import React from "react";
// import { Trans } from "react-i18next";
import {
  NODE_URL,
  ASSET_ID,
  API_URL,
  APP_ID,
  MASTER_KEY,
  BITPAY_API_URL,
  STRIPE_PUBLISHABLE_KEY,
  BITPAY_TOKEN,
  CURRENCY_CODE,
  SERVER_URL,
  BITPAY_NOTIFICATION_URL,
  STRIPE_NOTIFICATION_URL,
  TOPRICE,
  ONLYBUYELOOP
} from "../../config";
import styles from "../../styles/styles";
import i18n from "../../i18n";
import KlarnaPayment from "./KlarnaPayment";
import AdyenPayment from "./AdyenPayment";
import CircularProgress from "@material-ui/core/CircularProgress";
import CoinLogo from "../../assets/images/Token3D.png";
import ContractIcon from "../../assets/images/contract.svg";
import ContractIconDark from "../../assets/images/contract-dark.svg";
// import TermsIcon from "../../assets/images/terms.svg";
import InfoIcon from "../../assets/images/infosheet.svg";
import CardLogos from "../../assets/images/creditcard.svg";
import CryptoLogos from "../../assets/images/crypto.svg";
import SofortLogo from "../../assets/images/klarna.svg";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

const MySwal = withReactContent(Swal);

class Buy extends React.Component {
  constructor() {
    super();

    this.state = {
      tokenPrice: TOPRICE,
      tokenRange: 1,
      minPurchase: 100,
      maxPurchase: 4500,
      maxTokenSupply: undefined,
      tokensToBuy: 0,
      valueTokens: 0,
      valueEur: 0,
      contractDocument: "/Informationsblatt.pdf",
      termsDocument: "/sample.pdf",
      informationDocument: "/Informationsblatt.pdf",
      paymentMethod: "",
      fullName: "",
      address: {},
      stripeToken: "",
      loading: false,
      isTokenSet: false,
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
      checkbox4: false,
      checkbox5: false,
      checkbox6: false,
      paymentDisabled: true,
      confirmationSheet: "/Zeichnungsschein.pdf",
      confirmationSheetEnglish: "/SubscriptionForm.pdf",
      paymentTest: false
    };

    this.getData()
  }

  getData = () => {
    fetch("https://" + API_URL + "/classes/Settings/", {
      method: "GET",
      headers: new Headers({
        "X-Parse-Application-Id": APP_ID,
        "X-Parse-REST-API-Key": MASTER_KEY,
        "Content-Type": "application/json",
        "X-Parse-Session-Token": localStorage.getItem("token"),
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if(data["results"][0]['closed']) {
          window.location.href = "/login";
        }
        this.setState({ tokenData: data["results"] });
        // let totalPurchased = data["results"][0]["totalPurchased"];
        let totalSupply = data["results"][0]["TotalSupply"];
        this.setState({
          maxTokenSupply: data["results"][0]["TotalSupply"]
        })
        // get the left tokens from the wallet directly and the subtract them from the total supply for the REALTIME total purchased amount
        fetch(
          NODE_URL+"/assets/balance/3QcpnRhPcjmBK4fh7PMXXmjQ1zf2mU1tWcL/"+ASSET_ID,
          {
            method: "GET",
          }
        )
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            this.setState({
              totalPurchased: totalSupply - data.balance / 100,
              totalSupply: totalSupply
            });


            if(this.state.kycIsComplete && this.state.totalPurchased <= this.state.totalSupply - 100 && (ONLYBUYELOOP && this.state.email.endsWith("@eloop.to"))) {
              // do nothing here
              console.log("only eloop");
            } else if(this.state.kycIsComplete && this.state.totalPurchased <= this.state.totalSupply - 100 && !ONLYBUYELOOP) {
              // do nothing continue
            } else {
              window.location.href = "/login"
            }

          })
          .catch((e) => console.log(e));
        this.setState({
          // totalPurchased: totalPurchased,
          totalSupply: totalSupply,
        });

      })
      .catch((e) => console.log(e));
  };

  componentWillMount() {
    let token = localStorage.getItem("token");
    if (!token) {
      this.props.history.push("/login");
    } else {
      fetch("https://" + API_URL + "/users/me", {
        method: "GET",
        headers: new Headers({
          "X-Parse-Application-Id": APP_ID,
          "X-Parse-REST-API-Key": MASTER_KEY,
          "Content-Type": "application/json",
          "X-Parse-Session-Token": token,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error && data.code === 209) {
            this.logOut();
          }



          this.setState({ ...data });
           try {
            this.state.address.line1 = data.address1;
            this.state.address.line2 = data.address2;
            this.state.address.city = data.city;
            this.state.address.country = data.country;
            this.state.address.postcode = data.postcode;
            this.state.kycIsComplete = data.kycstatus
            this.state.email = data.email
          } catch (ex) {}

          //console.log(this.state);

        })
        .catch((err) => {
          console.log(err);
        });
    }

    localStorage.removeItem("_ed_token_");


  }
  componentDidMount() {
    // console.log(`queryString ${this.props.location.search}`)
    if(this.props.location.search.indexOf('paymenttest') !== -1) {
      this.setState({paymenttest: true})
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  openContract = () => {
    setTimeout(() => {
      window.open(this.state.contractDocument);
    }, 100);
  };

  openTerms = () => {
    setTimeout(() => {
      window.open(this.state.termsDocument);
    }, 100);
  };

  openInformationSheet = () => {
    setTimeout(() => {
      window.open(this.state.informationDocument);
    }, 100);
  };

  choosePurchaseToken = (amount) => {
    this.setState({
      valueTokens: amount,
      valueEur: amount,
    });
  };
  showZeichnungsschein = () => {
    MySwal.fire({
      background: '#191919',
      
      confirmButtonColor: '#49deb5',
      title:
      <div className="moreTokenModal"  style={{fontSize: "1rem"}}>
      <img src={ContractIcon} alt="contract ICone"/>
      <br/><br/>
      <p>
        Due to legal restrictions, an additional document (Zeichnungsschein) is needed for investments over 5.000 €
      </p>
      <hr />
      <h4>1. Step</h4>
      <p>Download and sign a Zeichnungsschein here:</p>

      <a href={this.state.confirmationSheet} style={{ color: "#49deb5", fontSize: "1.5rem" }} target="_blank" rel="noopener noreferrer">
        Zeichnungsschein (German)
      </a><br/><br/>
      <a href={this.state.confirmationSheetEnglish} style={{ color: "#49deb5", fontSize: "1.5rem" }} target="_blank" rel="noopener noreferrer">
        Subscription Form (English)
      </a>
      <hr />
      <h4>2. Step</h4>
      <p>Send us an email with the filled out document at:
      </p>
      <a href="mailto:support@eloop.one" style={{ color: "#49deb5", fontSize: "1.5rem"  }}>
        support@eloop.one
      </a>
      <hr />

      </div>
    });
    return;
  };

  setPurchaseValues() {
    var tokenValue =
      this.state.valueTokens +
      "###" +
      this.state.valueEur +
      "###" +
      this.state.paymentMethod;
    tokenValue = window.btoa(tokenValue);
    localStorage.setItem("_ed_token_", tokenValue);
  }

  chooseBitcoinPayment = () => {
    if (this.state.valueEur < this.state.minPurchase) {
      MySwal.fire({
        background: '#191919',
        icon: "warning",
        text: "Minium purchase 100 EOT" //i18n.t("minimum purchase of token is 100!"),
      });
      return;
    }
    if (this.state.valueEur > this.state.maxPurchase) {
      MySwal.fire({
        icon: "warning",
        text: "Maximum purchase amount online is 4500 EOT" //i18n.t("maximum purchase amount is 5000 EUR"),
      });
      return;
    }

    this.setState({ paymentMethod: "bitpay", loading: true }, () => {
      this.setPurchaseValues();
    });

    let bitPayRequest = {amount: (this.state.valueEur*this.state.tokenPrice).toFixed(0), email:  this.state.email};
    fetch('https://' + API_URL + '/functions/apnbitpay', {
        method: 'POST',
        headers: new Headers({
          'X-Parse-Application-Id': APP_ID,
          'X-Parse-REST-API-Key': MASTER_KEY,
          'Content-Type': 'application/json',
          'X-Parse-Session-Token': localStorage.getItem('token'),
        }),
        body: JSON.stringify(bitPayRequest)
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
            if(data.result.status) 
            {
              var invoiceId = window.btoa(data.result.invoiceId);
              localStorage.setItem("_ed_invoice_", invoiceId);
              window.location = data.result.redirectURL;
            } else {
              MySwal.fire({
                icon: "Error",
                text: data.result.message,
              });
            }
          }
      );
  };

  chooseSofortPayment = () => {
    this.setState({ paymentMethod: "sofort" }, () => {
      this.setPurchaseValues();
    });
  };

  chooseStripePayment = () => {
    if (this.state.valueEur < this.state.minPurchase) {
      MySwal.fire({
        icon: "warning",
        text: "Minimum purchase is 100 EOT" //i18n.t("minimum purchase of token is 100!"),
      });
      return;
    }
    if (this.state.valueEur > this.state.maxPurchase) {
      MySwal.fire({
        icon: "warning",
        text: "Maximum investment (online) is 5000€" //i18n.t("maximum purchase amount is 5000 EUR"),
      });
      return;
    }

    this.setState({ paymentMethod: "stripe" });
  };


  processBack = () => {
    this.setState({ isTokenSet: false });
  };

  processContinue = () => {
    if(this.state.totalPurchased + this.state.valueTokens > this.state.totalSupply) {
      MySwal.fire({
        icon: "warning",
        text: "Not enough EOT available to buy."//i18n.t("Not enough token available to buy."),
      });
    } else if (this.state.valueTokens < this.state.minPurchase) {
      MySwal.fire({
        background: '#191919',
        icon: "warning",
        text: "Please choose a token amount."/*i18n.t("Please choose the token amount."),*/
      });
      return;
    } else {
      this.setState({ isTokenSet: true });
    }
  };

  transactionBuyTokens = () => {
    console.log(this.state.tokensToBuy);
    var buyWithDec = this.state.tokensToBuy * 100 * 10000000;
    console.log(buyWithDec);
  };

  getActiveTokenButton(amount) {
    return amount === this.state.valueTokens
      ? "btn-price-option active"
      : "btn-price-option";
  }

  handleCheckChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({});

    this.setState(
      {
        [name]: value,
      },
      () => {
        this.updatePaymentButtons();
      }
    );
  };

  updatePaymentButtons = () => {
    if (
      this.state.checkbox1 &&
      // this.state.checkbox2 && // Information Notice checkboxes - to be added later
      this.state.checkbox3 &&
      this.state.checkbox4 &&
      this.state.checkbox5 && // Information Notice checkboxes - to be added later
      this.state.checkbox6
    ) {
      this.setState({ paymentDisabled: false });
    } else {
      this.setState({ paymentDisabled: true });
    }
  };

  render() {
    return (

      <div className="buy-token-block">
        {this.state.loading && (
          <div className="loading-body">
            <CircularProgress />
          </div>
        )}

        <div className="mainWrap">
          <div style={styles.detailboxStyle}>
            {!this.state.isTokenSet ? (
              <div>
              <h4
                style={{
                  paddingTop: "10px",
                  textAlign: "center",
                  textTransform: "uppercase",
                  color: "white",
                  fontWeight:"bolder"
                }}
              >
                1. {i18n.t("Choose your desired amount")}
                <p style={styles.txtStyle1}>
                  {i18n.t("You can buy Token multiple times.")}{" "}
                </p>
              </h4>
              <Container fluid={true} style={{"backgroundColor": "black", marginBottom: "16px"}}>
              <Row  className="text-center">
              <Col className="flex">
              <div className="field">
                <span className="data">
                <img
                  style={{ height: "136px" }} 
                  src={CoinLogo}
                  alt="Eloop One Logo"
                /><br/>
                ELOOP ONE TOKEN<br/>
                <span className="eotprice">{this.state.tokenPrice.toFixed(2)} €</span>
                </span>
                <p className="label"></p>
              </div>
              </Col>
              <Col className="flex">
              {this.state.totalPurchased+100 <= this.state.maxTokenSupply &&
              <div className="tokencon">
                <div
                  className={this.getActiveTokenButton(100)}
                  onClick={() => this.choosePurchaseToken(100)}
                >
                  <span>
                    100 <sub>EOT</sub>
                  </span>
                </div>
              </div>
              }
              {this.state.totalPurchased+ 250 <= this.state.maxTokenSupply &&
              <div className="tokencon">
                <div
                  className={this.getActiveTokenButton(250)}
                  onClick={() => this.choosePurchaseToken(250)}
                >
                  <span>
                    250 <sub>EOT</sub>
                  </span>
                </div>
              </div>
              }
              {this.state.totalPurchased+500 <= this.state.maxTokenSupply &&
              <div className="tokencon">
                <div
                  className={this.getActiveTokenButton(500)}
                  onClick={() => this.choosePurchaseToken(500)}
                >
                  <span>
                    500 <sub>EOT</sub>
                  </span>
                </div>
              </div>
              }
              {this.state.totalPurchased+1000 <= this.state.maxTokenSupply &&
              <div className="tokencon">
                <div
                  className={this.getActiveTokenButton(1000)}
                  onClick={() => this.choosePurchaseToken(1000)}
                >
                  <span>
                    1000 <sub>EOT</sub>
                  </span>
                </div>
              </div>
            }
              </Col>
              <Col className="flex">
              {this.state.totalPurchased+ 2000 <= this.state.maxTokenSupply &&
              <div className="tokencon">
                <div
                  className={this.getActiveTokenButton(2000)}
                  onClick={() => this.choosePurchaseToken(2000)}
                >
                  <span>
                    2000 <sub>EOT</sub>
                  </span>
                </div>
              </div>
              }
              {this.state.totalPurchased+3000 <= this.state.maxTokenSupply &&
              <div className="tokencon">
                <div
                  className={this.getActiveTokenButton(3000)}
                  onClick={() => this.choosePurchaseToken(3000)}
                >
                  <span>
                    3000 <sub>EOT</sub>
                  </span>
                </div>
              </div>
              }
              {this.state.totalPurchased+4150 <= this.state.maxTokenSupply &&
              <div className="tokencon">
                <div
                  className={this.getActiveTokenButton(4150)}
                  onClick={() => this.choosePurchaseToken(4150)}
                >
                  <span>
                    4150 <sub>EOT</sub>
                  </span>
                </div>
              </div>
            }
            {this.state.totalPurchased+4151 <= this.state.maxTokenSupply &&
            <div className="tokencon">
              <div
                className={this.getActiveTokenButton(4151)}
                onClick={() => this.showZeichnungsschein()}
              >
                <span>
                  +4151 <sub>EOT</sub>
                </span>
              </div>
            </div>
            }
              </Col>
              </Row>
              </Container>
              </div>
            ) : (
              <>
                <h4
                  style={{
                    paddingTop: "10px",
                    textAlign: "center",
                    textTransform: "uppercase",
                    color: "white",
                    fontWeight:"bolder"
                  }}
                >
                  2. {i18n.t("Download Documents")}
                </h4>

              
                <Row style={{textAlign: "center"}}>
                <Col className="flex">
                <div className="field">
                <img className="contractIcon" src={ContractIcon} alt="contract ICone" />
                <button
                  type="button"
                  className="downloaddocsbutton"
                  onClick={this.openContract}
                >
                Information sheet with attachments{/*i18n.t("Contract")*/}
                </button>
                </div>
                </Col>

                {/*<Col className="flex">
                <div className="field">
                <img src={ContractIconDark} alt="contract ICone" />
                <button
                  type="button"
                  className="downloaddocsbutton"
                  onClick={this.openContract}
                >
                  EOT White Paper <br/>
                  (Deutsch)
                </button>
                </div>
                </Col>
                <Col className="flex">
                <div className="field">
                <img src={ContractIconDark} alt="contract ICone" />
                <button
                  type="button"
                  className="downloaddocsbutton"
                  onClick={this.openContract}
                >
                  EOT White Paper <br/>
                  (English) {/*i18n.t("Contract")
                </button>
                </div>
                </Col>*/}
                </Row>
          
                {/* <div
                  className="documentcontainer"
                  style={{ padding: "3rem 0" }}
                >
                  <div className="document">
                    <div className="insidedoc">
                      <p>{i18n.t("Signed Contract")}</p>
                      <img src={ContractIcon} alt="contract ICone" />
                      <button
                        type="button"
                        className="downloaddocsbutton"
                        onClick={this.openContract}
                      >
                        Download {i18n.t("Contract")}
                      </button>
                    </div>
                  </div>
                  <div className="document"> // Information Notice checkboxes - to be added later
                    <div className="insidedoc">
                      <p>{i18n.t("EOT Information Sheet")}</p>
                      <img src={InfoIcon} alt="contract ICone" />
                      <button
                        type="button"
                        className="downloaddocsbutton"
                        onClick={this.openInformationSheet}
                      >
                        {i18n.t("Download Information Sheet")}
                      </button>
                    </div>
                  </div>
                </div> */}

                <div
                  className="checkboxcontainer"
                  style={{ padding: "3rem 0" }}
                >
                  <label style={styles.checkboxLabel}>
                    <input
                      name="checkbox1"
                      type="checkbox"
                      className="customCheckbox"
                      checked={this.state.checkbox1}
                      onChange={this.handleCheckChange}
                    />
                    <span className="checkboxText">
                      {i18n.t("register_form_agree_1")}
                    </span>
                  </label>

                  {/* <label style={styles.checkboxLabel}> // Information Notice checkboxes - to be added later
                    <input
                      name="checkbox2"
                      type="checkbox"
                      className="customCheckbox"
                      checked={this.state.checkbox2}
                      onChange={this.handleCheckChange}
                    />
                    <span className="checkboxText">
                      I have read the{" "}
                      <a
                        style={{ color: "#4adeb4" }}
                        href={this.state.informationDocument}
                      >
                        Information Notice
                      </a>{" "}
                      from Caroo Mobility GmbH (in German) and accept this.
                    </span>
                  </label>
                  <br></br> */}
                  <label style={styles.checkboxLabel}>
                    <input
                      name="checkbox4"
                      type="checkbox"
                      className="customCheckbox"
                      checked={this.state.checkbox4}
                      onChange={this.handleCheckChange}
                    />
                    <span className="checkboxText">
                      {i18n.t("register_form_agree_4")}
                    </span>
                  </label>

                  <label style={styles.checkboxLabel}> {/*// Information Sheet checkboxes - to be added later*/}
                    <input
                      name="checkbox5"
                      type="checkbox"
                      className="customCheckbox"
                      checked={this.state.checkbox5}
                      onChange={this.handleCheckChange}
                    />
                    <span className="checkboxText">
                      I have read the <a
                        style={{ color: "#4adeb4" }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={this.state.informationDocument}
                      >Information Sheet (Informationsblatt)</a>{" "} and all its attachments and agree to the terms and conditions.
                    </span>
                  </label>

                  <label style={styles.checkboxLabel}>
                    <input
                      name="checkbox6"
                      type="checkbox"
                      className="customCheckbox"
                      checked={this.state.checkbox6}
                      onChange={this.handleCheckChange}
                    />
                    <span className="checkboxText">
                      {i18n.t("register_form_agree_7")}
                    </span>
                  </label>
                  <label style={styles.checkboxLabel}>
                    <input
                      name="checkbox3"
                      type="checkbox"
                      className="customCheckbox"
                      checked={this.state.checkbox3}
                      onChange={this.handleCheckChange}
                    />
                    <span className="checkboxText">
                      I have the right to withdraw from the contract or my offer to conclude a contract within 14 (fourteen) days without stating any reason. I may exercise this right by, e.g., sending a letter to Caroo Mobility GmbH, Handelskai 92, 1200 Vienna, Austria, or via email to office@eloop.app. A simple sentence suffices, such as “I, (Name), born on (date), resident in (address) inform you that I withdraw from the contract or my offer to enter into a contract regarding the purchase of (number) Eloop-Tokens.
                    </span>
                  </label>
                </div>
                <div className="buyconfirm">
                  <h4>Token Purchase Summary</h4>
                  <div
                    style={{ margin: "15px auto" }}
                    className={this.getActiveTokenButton(
                      this.state.valueTokens
                    )}
                  >
                    <span>
                      {this.state.valueTokens}
                      <sub>EOT</sub>
                    </span>
                  </div>
                  <div className="purchase-summary">
                    <p>{this.state.valueTokens} EOT x {this.state.tokenPrice.toFixed(2)} €</p>

                    {/*<p>
                      {i18n.t("Est. Annual Yield (6.4%)")}: {" "}
                      {((this.state.valueTokens / 100) * 6.4 * this.state.tokenPrice).toFixed(2)} €
                    </p>*/}
                    <p style={{ fontSize: "1.5em" }}>
                      <b>
                        {i18n.t("Total Investment")}: {Math.floor(this.state.valueTokens * this.state.tokenPrice)} €
                      </b>
                    </p>
                  </div>
                </div>
                <h4
                  style={{
                    paddingTop: "10px",
                    textAlign: "center",
                    textTransform: "uppercase",
                    color: "white",
                    fontWeight:"bolder"
                  }}
                >
                  3. {i18n.t("Choose Your Payment Method")}
                </h4>
                {this.state.paymentMethod === "stripe" && (
                  <div>
                    <AdyenPayment amount={(this.state.valueTokens*this.state.tokenPrice).toFixed(0)} token={this.state.valueTokens}/>
                  </div>
                )}
                {this.state.paymentMethod === "sofort" && (
                  <div>
                    <KlarnaPayment amount={(this.state.valueTokens*this.state.tokenPrice).toFixed(0)} token={this.state.valueTokens} />
                  </div>
                )}
                <div className="textcenter" style={styles.spaceTop}>
                  <h1 className="seedphraseWarning">Be sure you have your seed phrase written down before you buy token!</h1>
                  

                  <button
                    type="button"
                    className="payment-button"
                    onClick={this.chooseSofortPayment}
                    disabled={this.state.paymentDisabled}
                  >
                    <p className="payheading">{i18n.t("Pay with Sofort")}</p>
                    <img src={SofortLogo} alt="Sofort / Klarna" style={{height: "36px"}}/>
                  </button>

                  <button
                    type="button"
                    className="payment-button"
                    onClick={this.chooseStripePayment}
                    disabled={this.state.paymentDisabled}
                  >
                    <p className="payheading">
                      {i18n.t("Pay with Credit Card")}
                    </p>
                    <img src={CardLogos} alt="Stripe Payment" />
                  </button>
                </div>
              </>
            )}
            <div className="textcenter" style={{ marginTop: "50px" }}>
              {!this.state.isTokenSet ? (
                <button
                  className="continuebutton"
                  onClick={this.processContinue}
                >
                  Continue
                </button>
              ) : (
                <>
                  {/* <button className='cashoutbutton' onClick={this.processBack}>
                  {i18n.t('Back')}
                </button> */}
                </>
              )}
            </div>
            <div style={styles.clearBoth}></div>
            <p style={styles.txtStyle2}>
              {/* {i18n.t("buy_text_1")}{" "} */}
              <span>
                DUE TO LEGAL RESTRICTIONS, AN{" "}
                <a
                  href={this.state.confirmationSheet}
                  style={{ color: "#49deb5" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ADDITIONAL DOCUMENT (ZEICHNUNGSSCHEIN)
                </a>{" "}
                IS NEEDED FOR INVESTMENTS OVER 5.000 EUR. IF YOU ARE INTERESTED,
                PLEASE SEND US AN EMAIL WITH THE FILLED OUT DOCUMENT
                (ZEICHNUNGSSCHEIN) AT{" "}
                <a href="mailto:support@eloop.one" style={{ color: "#49deb5" }}>
                  support@eloop.one
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Buy;


/** <button
                    type="button"
                    className="payment-button"
                    onClick={this.chooseBitcoinPayment}
                    disabled={this.state.paymentDisabled}
                  >
                    <p className="payheading">Pay with Crypto</p>
                    <img
                      src={CryptoLogos}
                      alt={i18n.t(
                        "BitPay Supported Currencies (BTC, BCH, ETH, XRP)"
                      )}
                    />
                  </button>**/