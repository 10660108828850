import React from "react";
import paymentMethodsMock from "./klarna.json";
import {
  API_URL,
  APP_ID,
  ASSET_ID,
  MASTER_KEY,
  ENCRYPTION_KEY,
  TOPRICE
} from '../../config';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

class KlarnaPayment extends React.Component {
  constructor(props) {
    super(props);
    this.initKlarnaPayment = this.initKlarnaPayment.bind(this);
  }

  componentDidMount() {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href =
      "https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/3.4.0/adyen.css";
    document.head.appendChild(link);

    const script = document.createElement("script");
    script.src =
      "https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/3.4.0/adyen.js";
    script.async = true;
    script.onload = this.initKlarnaPayment; // Wait until the script is loaded before initiating AdyenCheckout
    document.body.appendChild(script);

  }

  initKlarnaPayment() {
    const configuration = {
      locale: "de-DE",
      environment: "live",
      originKey: "pub.v2.2615454035882888.aHR0cHM6Ly9kYXNoYm9hcmQuZWxvb3Aub25l.7DSM-vl-PR69DdYMytly9KW7ovTCUKdv37VL75OQTws",
      paymentMethodsResponse: {
        "paymentMethods": [
           {
            "name": "Online bank transfer.",
            "supportsRecurring": false,
            "type": "directEbanking"
           }/*,
           {
            "name": "Online bank transfer.",
            "supportsRecurring": false,
            "type": "eps"
           },{
            "name": "Online bank transfer.",
            "supportsRecurring": false,
            "type": "giropay"
           }*/


        ]
      },
      amount: {
        value: (this.props.amount * 100),
        currency: "EUR"
      }
    };

    const checkout = new window.AdyenCheckout(configuration);

    checkout
      .create("dropin", {
        onSubmit: (state, dropin) => {

        fetch('https://' + API_URL + '/functions/adyenpayment', {
        method: 'POST',
        headers: new Headers({
          'X-Parse-Application-Id': APP_ID,
          'X-Parse-REST-API-Key': MASTER_KEY,
          'Content-Type': 'application/json',
          'X-Parse-Session-Token': localStorage.getItem('token'),
        }),
        body: JSON.stringify({amount: configuration.amount, initData: state.data})
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
           console.log(data);
           if(data.result.redirectUrl != undefined) {
            window.location.href = data.result.redirectUrl; 
           } else if(data.result.redirect.url != undefined) {
            window.location.href = data.result.redirect.url; 
            } else {
              MySwal.fire({
              icon: "error",
              text: "Error in Payment Gateways"
            }).then(() => {
              window.location.href = "/buy"
            });
            }
           

        })
        .catch((err) => console.log(err));

        },
        onAdditionalDetails: (state, dropin) => {
         // Not Used in this Implementation
        }
      })
      .mount(this.ref);
  }

  render() {
    return (
      <div
        ref={ref => {
          this.ref = ref;
        }}
      />
    );
  }
}

export default KlarnaPayment;
