import React from "react";
import {
  NODE_URL,
  API_URL,
  APP_ID,
  ASSET_ID,
  MASTER_KEY,
  ENCRYPTION_KEY,
} from "../../config";
import styles from "../../styles/styles";
import i18n from "./../../i18n";
import styled from "@emotion/styled";
import SeedPhrase from "./../Modal/SeedPhrase";

import { withRouter } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const { seedUtils } = require("@0bsnetwork/zbs-transactions");

// const Values = styled.div`
//   span {
//     font-weight: bold;
//     font-size: 1.2em;
//   }
//   p {
//     font-size: 0.7em;
//     opacity: 0.5;
//     text-transform: uppercase;
//   }
// `;

// const Container = styled.div`
//   background-color: white;
//   padding: 2em 4em 0.5em;
//   box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
//     0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
//     0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
//     0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
//   border: 1px solid rgba(32, 39, 140, 0.125);
//   border-radius: 0.25rem;
// `;
// const FiftyContainer = styled.div`
//   display: ${window.screen.width < 570 ? `inline` : `flex`};
//   flex-direction: row;
// `;
// const Fifty = styled.div`
//   width: 50%;
//   display: inline;
// `;
// const FiftyRight = styled.div`
//   width: 50%;
//   display: inline;
//   text-align: right;
// `;
// const Email = styled.p`
//   margin-bottom: 0;
//   font-size: 1.4em;
// `;
const Passed = styled.span`
  background-color: #00fbc2;
  padding: 0.5rem 1rem;
  border-radius: 50px;
  color: #191919;
  font-weight: 500;
  font-size: 0.8em !important;
`;
const NotPassed = styled.span`
  background-color: orange;
  padding: 0.2em;
  border-radius: 5px;
  color: white;
  font-size: 0.9em !important;
`;
const Logout = styled.div`
  font-family: "Sofia Pro";
  color: white;
  text-align: center;
`;
const Buttons = styled.div`
  display: block;
  justify-content: center;
`;
const Support = styled.p`
  margin-top: 10px;
  font-size: 14px;
  color: white;
  text-align: center;
  text-transform: uppercase;
`;
class Details extends React.Component {
  constructor() {
    super();
    this.state = {
      objectId: "",
      fullName: "",
      firstName: "",
      lastName: "",
      email: "",
      address1: "",
      address2: "",
      city: "",
      postcode: "",
      country: "",
      countryText: "",
      nationality: "",
      language: "",
      purchasecurrency: "",
      purchasevalue: "",
      walletaddress: "",
      walletaddresstosave: "",
      seedPharse: "",
      loading: true,
      viewSeedPharse: false,
      isOpenSavePhrase: false,
      balance: 0,
      tabId: "basic-tab-1",
      eloopAccount: "",
      eloopAccountIsLinked: false,
    };
  }

  componentWillMount() {
    let token = localStorage.getItem("token");
    if (!token) {
      this.props.history.push("/login");
    } else {
      fetch("https://" + API_URL + "/users/me", {
        method: "GET",
        headers: new Headers({
          "X-Parse-Application-Id": APP_ID,
          "X-Parse-REST-API-Key": MASTER_KEY,
          "Content-Type": "application/json",
          "X-Parse-Session-Token": token,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          let errorInBackend = false;
          if (data.error && data.code === 209) {
            this.logOut();
          }
          this.setState({ ...data });
          localStorage.setItem("objectId", data.objectId);
          if (
            !this.state.walletaddress &&
            this.state.email.length > 0 &&
            this.state.objectId.length > 0
          ) {
            // TODO: Make sure new wallet doesnt get generated if problem with API call
            let encryptionKey = ENCRYPTION_KEY + "###" + this.state.email;
            let encryptedSeed = localStorage.getItem(
              this.state.email + "_encryptedSeed"
            );
            if (!encryptedSeed) {
              this.createWallet();
            } else {
              alert(
                i18n.t(
                  "You have a local storage wallet but its not linked to your account. Please write down your seedphrase and contact support@eloop.one"
                )
              );
              if (encryptedSeed) {
                //console.log(encryptedSeed)
                var seedDecrypted = seedUtils.decryptSeed(
                  encryptedSeed,
                  encryptionKey,
                  2048
                );
                //console.log(seedDecrypted)
                if (seedDecrypted !== "") {
                  this.setState({ seedPharse: seedDecrypted });
                }
              }
            }
          } else {
            let encryptionKey = ENCRYPTION_KEY + "###" + this.state.email;
            let encryptedSeed = localStorage.getItem(
              this.state.email + "_encryptedSeed"
            );
            if (encryptedSeed) {
              //console.log(encryptedSeed)

              var seedDecrypted = seedUtils.decryptSeed(
                encryptedSeed,
                encryptionKey,
                2048
              );
              //console.log(seedDecrypted)

              if (seedDecrypted !== "") {
                let walletAddr = seedUtils.Seed.fromExistingPhrase(
                  seedDecrypted,
                  "Z"
                ).address;
                if (this.state.walletaddress == walletAddr) {
                  // everything went good here
                  this.setState({ seedPharse: seedDecrypted });
                } else {
                  this.setState({ seedPharse: seedDecrypted });
                  //alert(i18n.t("Your Localstorage Wallet does not match with the current linked wallet. Please contact support@eloop.one and write down your seedphrase")); --> todo enable it
                }
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  linkEloopAccounts = (emails) => {
    let token = localStorage.getItem("token");
    if (!token) {
      this.props.history.push("/login");
    }
    Swal.fire({
      title: "What is your ELOOP Carsharing Email address?",
      confirmButtonColor: "rgb(74, 222, 181)",
      input: "email",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Link accounts",
      showLoaderOnConfirm: true,
      preConfirm: (email) => {
        let icon = "";
        fetch("https://" + API_URL + "/functions/linkEloopAccounts", {
          method: "POST",
          headers: new Headers({
            "X-Parse-Application-Id": APP_ID,
            "X-Parse-REST-API-Key": MASTER_KEY,
            "Content-Type": "application/json",
            "X-Parse-Session-Token": token,
          }),
          body: JSON.stringify({
            email: email,
          }),
        })
          .then((res) => res.json())
          .then(async (data) => {
            if (data.error && data.code === 209) {
              this.logOut();
            }
            if (data.result.statusCode == 200) {
              icon = "success";
              this.state.eloopAccount = email;
              this.state.eloopAccountIsLinked = true;
            } else if (data.result.statusCode == 400) {
              icon = "error";
            } else if (data.result.statusCode == 404) {
              icon = "question";
            }
            Swal.fire({
              icon: icon,
              text: data.result.msg,
              confirmButtonColor: "rgb(74, 222, 181)",
            });
          })
          .catch((err) => {
            console.log(err);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  updateBalance = () => {
    try {
      fetch(
        NODE_URL +
          "/assets/balance/" +
          this.state.walletaddress +
          "/" +
          ASSET_ID
      )
        .then((response) => {
          return response.json();
        })
        .then((balance) => {
          if (balance.balance) {
            var bal = balance.balance / 100;
            this.setState({ balance: bal, loading: false });
          } else {
            this.setState({ balance: 0, loading: false });
          }
        });
    } catch (err) {
      console.log("Error fetching data-----------", err);
    }
  };

  async componentDidMount() {
    //this.updateBalance()
    this.timer = setInterval(() => this.updateBalance(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }

  createWallet = () => {
    // 1.  stop the form from submitting
    //event.preventDefault();

    var seed = window.zbs.createSeed();
    //console.log("Seed", seed);

    const walletaddresstosave = seedUtils.Seed.fromExistingPhrase(
      seed.phrase,
      "Z"
    ).address;

    // try to save the local storage //
    let errorInSavingToLocalStorage = false;

    let encryptionKey = ENCRYPTION_KEY + "###" + this.state.email;
    try {
      var seedObj = seedUtils.Seed.fromExistingPhrase(seed.phrase, "Z");
      var seedEncrypted = seedObj.encrypt(encryptionKey, 2048);
      localStorage.setItem(this.state.email + "_encryptedSeed", seedEncrypted);
    } catch (ex) {
      alert(i18n.t("Error adding wallet address, please contact support."));
      errorInSavingToLocalStorage = true;
    }

    if (!errorInSavingToLocalStorage) {
      let tryToCheckIfitsSaved = localStorage.getItem(
        this.state.email + "_encryptedSeed"
      );
      if (tryToCheckIfitsSaved) {
        fetch(
          "https://" + API_URL + "/users/" + localStorage.getItem("objectId"),
          {
            method: "PUT",
            headers: new Headers({
              "X-Parse-Application-Id": APP_ID,
              "X-Parse-REST-API-Key": MASTER_KEY,
              "Content-Type": "application/json",
              "X-Parse-Session-Token": localStorage.getItem("token"),
            }),
            body: JSON.stringify({
              walletaddress: walletaddresstosave,
            }),
          }
        )
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            if (data.error) {
              alert(
                i18n.t("Error adding wallet address, please contact support.")
              );
            } else {
              this.setState({ seedPharse: seed.phrase });
              this.setState({ walletaddress: walletaddresstosave });
              this.setState({ walletaddresstosave: "" });
              this.setState({ isOpenSavePhrase: true });
            }
          })
          .catch((err) => console.log(err));
      }
    }

    //event.currentTarget.reset();
  };

  startKyc = () => {
    //if (window.confirm(i18n.t("Kyc_text_1"))) {
    fetch("https://" + API_URL + "/functions/startKyc", {
      method: "POST",
      headers: new Headers({
        "X-Parse-Application-Id": APP_ID,
        "X-Parse-REST-API-Key": MASTER_KEY,
        "Content-Type": "application/json",
        "X-Parse-Session-Token": localStorage.getItem("token"),
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        // console.log(data)
        //window.open(data.result.TargetURL, "_blank");
        //window.location.reload();
        window.location.href = data.result.TargetURL;
      })
      .catch((err) => console.log(err));
    // } else {
    // Do nothing!
    // }
  };

  logOut = () => {
    localStorage.removeItem("token");
    this.props.history.push("/login");
  };

  viewSeedPharse = () => {
    this.setState({ viewSeedPharse: true });
    setTimeout(
      function () {
        this.setState({ viewSeedPharse: false });
      }.bind(this),
      30000
    );
  };

  importSeedPhrase = () => {
    var phrase = prompt("Enter Seed Phrase", "");

    if (phrase != null) {
      let encryptionKey = ENCRYPTION_KEY + "###" + this.state.email;
      var seedObj = seedUtils.Seed.fromExistingPhrase(phrase, "Z");
      if (seedObj.address == this.state.walletaddress) {
        var seedEncrypted = seedObj.encrypt(encryptionKey, 2048);
        localStorage.setItem(
          this.state.email + "_encryptedSeed",
          seedEncrypted
        );
        this.setState({ seedPharse: phrase });
        //this.setState({ walletaddress: walletaddresstosave });
        alert("Your Seedphrase was imported: " + seedObj.address);
      } else {
        alert(
          "Your seed is not matching with the wallet: " +
            this.state.walletaddress
        );
      }

      // console.log(seedObj.address)
    }
  };

  closeSavePhraseModal = () => {
    this.setState({ isOpenSavePhrase: false });
    // zap for tracking when users confirmed their seedphrase
    try {
      if (
        this.state.firstName &&
        this.state.lastName &&
        this.state.email &&
        this.state.walletaddress
      ) {
        let userGotSeedPhrase = {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
          wallet: this.state.walletaddress,
        };
        fetch("https://hooks.zapier.com/hooks/catch/2003647/onv0vm0/", {
          method: "POST",
          body: JSON.stringify(userGotSeedPhrase),
        });
      }
    } catch (e) {
      console.log("Error: " + e);
    }
  };

  selectEn = () => {
    i18n.changeLanguage("en");
    localStorage.setItem("I18N_LANGUAGE", "en");
  };

  selectDe = () => {
    i18n.changeLanguage("de");
    localStorage.setItem("I18N_LANGUAGE", "de");
  };

  formatDate(inputDate) {
    return new Date(inputDate).toLocaleDateString("de-DE");
  }

  render() {
    return (
      <div>
        <h1 className="seedphraseWarning">
          Make sure your seed phrase is written down or you won't have access to
          your wallet!
        </h1>

        <Container fluid={true} style={{ backgroundColor: "black" }}>
          {/*<h3>Funds can't be withdrawn without it!</h3>*/}
          <h1
            className="field"
            style={{ padding: "0.8rem", textAlign: "center" }}
          >
            WALLET
          </h1>
          <Row>
            <Col className="text-center">
              <div className="field">
                <span className="accountdata">
                  {this.state.kycstatus && (
                    <span>{!this.state.loading && this.state.balance} EOT</span>
                  )}
                </span>
                <p className="label">TOKEN BALANCE</p>
              </div>
              <div className="field">
                <a
                  style={{ color: "#49deb5" }}
                  className="data accountLinks"
                  href={
                    "https://explorer.0bsnetwork.com/address/" +
                    this.state.walletaddress
                  }
                >
                  {this.state.walletaddress}
                </a>
                <p className="label">WALLET ADDRESS</p>
              </div>
              <div className="field">
                <span className="data accountLinks">
                  https://dashboard.eloop.one/register?ref={this.state.userRef}
                </span>
                <p className="label accountLinks">REFERRAL-LINK</p>
              </div>
            </Col>
            <Col className="text-center">
              <div className="field">
                <span className="accountdata">
                  {this.state.kycstatus === false ? (
                    <NotPassed>NOT PASSED</NotPassed>
                  ) : (
                    <Passed>PASSED</Passed>
                  )}
                </span>{" "}
                <p className="label">KYC STATUS</p>
              </div>
              <div className="field">
                <span className="accountdata">
                  {this.state.kycstatus === true &&
                  this.state.kyccomment !== "AWAITING APPLICATION"
                    ? "KYC COMPLETED"
                    : this.state.kycstatus === false &&
                      this.state.kyccomment === "IN PROGRESS"
                    ? "PLEASE CHECK YOUR EMAIL FOR KYC COMPLETION"
                    : this.state.kyccomment}
                </span>
                <p className="label">KYC MESSAGE</p>
              </div>
              <Buttons>
                {/* {this.state.seedPharse == "" && ( */}
                <button
                  className="importWalletButton"
                  onClick={this.importSeedPhrase}
                >
                  {i18n.t("Import wallet")}
                  {/* only works with linked wallet */}
                </button>
                {/* )} */}

                {this.state.kyccomment === "AWAITING APPLICATION" &&
                  !this.state.kycstatus && (
                    <button
                      style={{ marginRight: "0", marginBottom: "1em" }}
                      href="#!"
                      onClick={() => {
                        this.startKyc();
                      }}
                      className="loginbutton"
                    >
                      {i18n.t("Start")} {i18n.t("KYC")} Application
                    </button>
                  )}
                {((this.state.kyccomment === "IN PROGRESS" &&
                  !this.state.kycstatus) ||
                  this.state.kycstatus === false) && ( //
                  <p>
                    if you expierence any problems regarding kyc reach out to{" "}
                    <a href="mailto:support@eloop.one">support@eloop.one</a>
                  </p>
                )}
              </Buttons>
            </Col>
          </Row>
        </Container>
        <br />
        <Container fluid={true} style={{ backgroundColor: "black" }}>
          <h1
            className="field"
            style={{ padding: "0.8rem", textAlign: "center" }}
          >
            CARSHARING ACCOUNT
          </h1>
          <Row>
            <Col className="text-center">
              <div className="field">
                <span className="accountdata">{this.state.eloopAccount}</span>
                <p className="label">Linked Carsharing Account</p>
              </div>
            </Col>
            <Col className="text-center">
              {!this.state.eloopAccountIsLinked ? (
                <button
                  type="button"
                  className="linkbutton"
                  onClick={this.linkEloopAccounts}
                >
                  <b>Link Accounts</b>
                </button>
              ) : null}
            </Col>
          </Row>
        </Container>
        <br />
        <Container fluid={true} style={{ backgroundColor: "black" }}>
          <h1
            className="field"
            style={{ padding: "0.8rem", textAlign: "center" }}
          >
            EOT ACCOUNT
          </h1>
          <Row>
            <Col className="text-center">
              <div className="field">
                <span className="accountdata">
                  {this.state.firstName} {this.state.lastName}
                </span>
                <p className="label">NAME</p>
              </div>
              <div className="field">
                <span className="accountdata">
                  {this.formatDate(this.state.DOB)}
                </span>
                <p className="label">BIRTHDAY</p>
              </div>
              <div className="field">
                <span className="accountdata">{this.state.email}</span>
                <p className="label">EMAIL</p>
              </div>
            </Col>
            <Col className="text-center">
              <div className="field">
                <span className="accountdata">
                  {this.state.address1} {this.state.address2}
                  <br />
                  {this.state.postcode} {this.state.city}
                  <br />
                  {this.state.countryText}
                </span>
                <p className="label">ADDRESS</p>
              </div>
              <div style={{ textAlign: "center", color:"white",paddingTop: ".5em" }}>
                <a href="/passwordreset" style={styles.linkStyle}>
                  <span style={{ color: "white", textDecoration: "underline" }}>
                    {i18n.t("Change Password")}
                  </span>
                </a>
              </div>
              <Logout>
                <span
                  style={{
                    textDecoration: "initial",
                    paddingRight: "1em",
                    fontSize: "1.3em",
                  }}
                >
                  {/* Language change to be fully implemented at a later point in time! */}
                  {/* <a type="button" onClick={this.selectEn}>
              🇺🇸
            </a>
            |
            <a type="button" onClick={this.selectDe}>
              🇦🇹
            </a> */}
                </span>

                <button
                  type="submit"
                  className="logoutbutton"
                  onClick={this.logOut}
                  style={{ marginBottom: "3em" }}
                >
                  {i18n.t("Logout")}
                </button>
              </Logout>
            </Col>
          </Row>
          <div>
            {this.state.seedPharse !== "" && (
              <div style={styles.detailboxStyle}>
                <span style={styles.detailTitleStyle}>
                  {i18n.t("Seed Phrase")}
                </span>
                {this.state.viewSeedPharse ? (
                  <span style={styles.pharsedetailItemStyle}>
                    {this.state.seedPharse}
                  </span>
                ) : (
                  <button
                    className="logoutbutton"
                    onClick={this.viewSeedPharse}
                  >
                    {i18n.t("View")}
                  </button>
                )}
              </div>
            )}
          </div>

          <SeedPhrase
            open={this.state.isOpenSavePhrase}
            handleClose={this.closeSavePhraseModal}
            seedPharse={this.state.seedPharse}
          />
        </Container>
        <br />
        <Container fluid={true} style={{ backgroundColor: "black" }}>
          <h1
            className="field"
            style={{ padding: "0.8rem", textAlign: "center" }}
          >
            FAQs
          </h1>
          <Row>
            <Col className="text-center">
              <div className="field">
                <span className="accountdata">
                  <a
                    style={{ color: "#49deb5" }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://eloop.one/frequently-asked-questions/"
                  >
                    Frequently Asked Questions
                  </a>
                </span>
              </div>
            </Col>
            <Col className="text-center">
              <div className="field">
                <span className="accountdata">
                  <a
                    style={{ color: "#49deb5" }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://eloop.app"
                  >
                    ELOOP Carsharing
                  </a>
                </span>
              </div>
            </Col>
          </Row>
        </Container>
        <br />
        <Container fluid={true} style={{ backgroundColor: "black" }}>
          <h1
            className="field"
            style={{ padding: "0.8rem", textAlign: "center" }}
          >
            LINKS
          </h1>
          <Row>
            <Col className="text-center">
              <div className="field">
                <span className="accountdata">
                  <a style={{ color: "#49deb5" }} href="https://t.me/eloopone">
                    ELOOP ONE
                  </a>
                </span>
                <p className="label">TELEGRAM EOT</p>
              </div>
              <div className="field">
                <span className="accountdata">
                  <a
                    style={{ color: "#49deb5" }}
                    href="https://eloop.app"
                  >
                    ELOOP Carsharing
                  </a>
                </span>
                <p className="label">ELOOP</p>
              </div>
            </Col>
            <Col className="text-center">
              <div className="field">
                <span className="accountdata">
                  <a
                    style={{ color: "#49deb5" }}
                    href="https://play.google.com/store/apps/details?id=com.vulog.carshare.eloop"
                  >
                    Carsharing Android App
                  </a>
                </span>
                <p className="label">Android App</p>
              </div>
              <div className="field">
                <span className="accountdata">
                  <a
                    style={{ color: "#49deb5" }}
                    href="https://apps.apple.com/at/app/eloop-elektrisches-carsharing/id1465221970"
                  >
                    Carsharing iOS App
                  </a>
                </span>
                <p className="label">iOS App</p>
              </div>
            </Col>
          </Row>
        </Container>
        <br />
        <Container fluid={true} style={{ backgroundColor: "black" }}>
          <h1
            className="field"
            style={{ padding: "0.8rem", textAlign: "center" }}
          >
            DOCUMENTS
          </h1>
          <Row className="gap-2" >
            <Col className="text-center field">
              <div className="">
                <span className="accountdata">
                  <a
                    style={{ color: "#4adeb4" }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={"/Informationsblatt.pdf"}
                  >
                    Information Sheet (Informationsblatt)
                  </a>
                </span>
                <p className="label">Information Sheet</p>
              </div>
            </Col>
            <Col className="text-center field">
              <div className="">
                <div className="accountdata">
                  <a
                    style={{ color: "#4adeb4", marginTop:"1rem" }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={"/Zeichnungsschein.pdf"}
                  >
                    Zeichnungsschein
                  </a>
                </div>
                <div className="label">Zeichnungsschein German</div>
              </div>
            </Col>
          </Row>
        </Container>

        <div>
          <Container fluid></Container>

          <Support>
            If you encounter any problems, please don't hesitate to contact us{" "}
            <a href="mailto:support@eloop.one" style={{ color: "#49deb5" }}>
              support@eloop.one
            </a>
          </Support>
        </div>

        {/*    <div style={styles.detailboxStyle}>
          <span style={styles.detailTitleStyle}>KYC {i18n.t('Status')}</span>{' '}
          <span style={styles.detailItemStyle}>
            {this.state.kycstatus === false ? 'NOT PASSED' : 'PASSED'}
          </span>
        </div>

        <div style={styles.detailboxStyle}>
          <span style={styles.detailTitleStyle}>KYC {i18n.t('Message')}</span>
          <div style={{ height: 10 }}></div>{' '}
          <span style={styles.detailItemStyle}>{this.state.kyccomment}</span>{' '}
          {this.state.kyccomment === 'AWAITING APPLICATION' && (
            <a
              href='#!'
              onClick={() => {
                this.startKyc();
              }}
              style={styles.kycButtonStyle}
            >
              {i18n.t('Start')} {i18n.t('KYC')} >>
            </a>
          )}
        </div>

        {this.state.seedPharse !== '' && this.state.kycstatus && (
          <div style={styles.detailboxStyle}>
            <span style={styles.detailTitleStyle}>{i18n.t('Seed Phrase')}</span>
            {this.state.viewSeedPharse ? (
              <span style={styles.pharsedetailItemStyle}>
                {this.state.seedPharse}
              </span>
            ) : (
              <button style={styles.viewbtnStyle} onClick={this.viewSeedPharse}>
                View
              </button>
            )}
          </div>
        )} */}
      </div>
    );
  }
}

export default withRouter(Details);

/* {this.state.kycstatus && (
  <div>
    <div style={styles.detailboxStyle}>
      <span style={styles.detailTitleStyle}>
        {i18n.t('Wallet Address')}
      </span>
      <span style={styles.detailItemStyle}>
        {this.state.walletaddress} &nbsp;
      </span>
      <br />
    </div>
    <div style={styles.detailboxStyle}>
      <span style={styles.detailTitleStyle}>
        {i18n.t('Token Balance')}
      </span>
      <span style={styles.detailItemStyle}>
        {!this.state.loading && this.state.balance} &nbsp;
      </span>
      <br />
    </div>
    <div style={{ height: 40 }} ></div>
   <form className="user-create" onSubmit={this.createWallet}>
      <div style={styles.inputBoxStyle}>

      </div>


    <button type="submit" style={styles.submitAddressStyle}>Save Address</button>

    </form>
  </div>
)} */
