const isLocalhost = Boolean(
    window.location.hostname === '192.168.1.66'
);
export const APP_ID = 'eloopapp';
export const MASTER_KEY = '27nr36YLe5NG9yOa3OZ07L6IBVb';
export const API_URL = 'api.eloop.one';
export const API_URL_ELOOP_AT = 'api-endpoint.eloop.to';
export const ASSET_ID = '64cT2fca2T6pcMYU8n5hjXWE4G2KPFyaMUGihd3bFKMD';
export const NODE_URL = 'https://node1.0bsnetwork.com';
export const ENCRYPTION_KEY = 'WVBE6t9RL59KT7SKM8hecRVHwiEm7uy4';
export const STRIPE_PUBLISHABLE_KEY = 'pk_live_tY0PJEGyU6PgHnSGOEDytb4g';
export const CURRENCY_CODE = 'EUR';
export const CURRENCY_CODE_HTML = '€';
export const BITPAY_API_URL = 'https://bitpay.com';
export const BITPAY_TOKEN = 'BBsg9Hfh3B82MGStgrnQ8eqvkLNyFdkf3dB5r2ipMDFH';
export const BITPAY_NOTIFICATION_URL = isLocalhost ? 'https://6473be54.ngrok.io/ipnbitpay' : 'https://api.eloop.one/functions/ipnbitpay';
export const STRIPE_NOTIFICATION_URL = isLocalhost ? 'http://localhost:3030/ipnstripe' : 'https://api.eloop.one/functions/ipnstripe';
export const SERVER_URL = isLocalhost ? 'http://localhost:3000' : 'https://dashboard.eloop.one';
export const NODE_EXPLORER_URL = 'https://explorer.0bsnetwork.com/tx/';
export const TOPRICE = 1.2
export const ONLYBUYELOOP = true;

