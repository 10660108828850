import React from "react";
import styled from "@emotion/styled";
import Tesla from "../../assets/images/tesla-model-3.png";
import Moment from "moment";
// import momentDurationFormatSetup from 'moment-duration-format';
import i18n from "../../i18n";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 1.25em 0.25em;
  box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
    0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
    0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
    0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);

  border: 1px solid rgba(32, 39, 140, 0.125);
  border-radius: 0.25rem;
`;
const Active = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 25px;
`;
const Plate = styled.div`
  background-color: white;
  font-weight: bold;
  font-size: 1.2em;
`;
const Span = styled.span`
  font-weight: 700;
  text-transform: uppercase;
  padding: 5px 10px;
  min-width: 19px;
  color: #fff;
  background-color: rgb(74, 222, 181);
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: initial;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;
const SecondRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  div p {
    font-size: 0.7em;
    text-transform: uppercase;
    text-align: center;
    opacity: 0.5;
  }
  div span {
    font-weight: bold;
  }
`;
const ThirdRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  div p {
    font-size: 0.7em;
    text-transform: uppercase;
    text-align: center;
    opacity: 0.5;
  }
  div span {
    font-weight: bold;
  }
`;

const durationFormatter = (cell) => {
  if (cell) {
    return Moment.duration(Number(cell.toFixed(0)), "minutes").format(
      "H:mm:ss"
    );
  } else return 0;
};


const revPerCarConst = (data) => {
  let costPerCar = Math.floor(( new Date() - Date.parse(data.data.dayUsingStarts.iso) ) / 86400000)*((data.data.monthlyCostEur*12)/360)
  var revPerCar = (data.data.totalRevenue-(costPerCar)).toFixed(2);
  var colorForRevPerCar = "green";
  if(revPerCar < 0) { colorForRevPerCar = "red" }
  return {revPerCar: revPerCar,colorForRevPerCar: colorForRevPerCar, costPerCar: costPerCar}
}

  const getData = () => {
    let totalSupply = 0;
    let totalPurchased = 0;
   fetch("https://nodes.0bsnetwork.com/assets/balance/3QcpnRhPcjmBK4fh7PMXXmjQ1zf2mU1tWcL/64cT2fca2T6pcMYU8n5hjXWE4G2KPFyaMUGihd3bFKMD", {
            method: "GET",
        }).then((response) => {
            return response.json();
        }).then((data) => {
            totalPurchased = 240000 -  data.balance/ 100;
        }).catch((e) => console.log(e));
    //return "TEST"+totalPurchased
  };



const Vehicle = (data) => (

  <Container style={{ marginBottom: "2rem" }}>
    <Active>
      <img
        src={Tesla}
        alt="Eloop Tesla Model 3"
        style={{ paddingRight: "20px", width: "196px", height: "82px" }}
      />
      <div className="textvehicle">

            <div className="dot-red">
              <div className="dot-red-pulse" />
            </div>
            <Span>{i18n.t("Not Active")}</Span>
      </div>

    </Active>
     <SecondRow>
      <div>
        <p>{i18n.t("This model 3 will be added to the fleet very soon")}</p>
      </div>
    </SecondRow>

  </Container>
);

export default Vehicle;
