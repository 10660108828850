import React from "react";
import ReactApexChart from "react-apexcharts";
import i18n from "../../i18n";
import { APP_ID, API_URL, MASTER_KEY } from "../../config";

import { Line, defaults, Chart, Radar, Bar } from "react-chartjs-2";

const Month = new Date().getMonth();
const Year = new Date().getFullYear();
function getDaysInMonth(month, year) {
  var date = new Date(year, month, 1);
  var days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
}
function colorSelector(yearAndMonthString) {
    try {
    const year = yearAndMonthString.split("-")[0];
    const month = yearAndMonthString.split("-")[1];
    const colors = [
      "white",
      "#a20a0a",
      "#f47c7c",
      "#01c5c4",
      "rgba(255, 206, 86, 1)",
      "#1fab89",
      "#07689f",
      "#f73859",
      "#ffb6b6",
      "#00bbf0",
      "#F5A962",
      "#207561",
      "#14279B",
      "#3F0713",
      "#6D8299"
    ];

    const yearIndex = parseInt(year) - 2020;
    const colorIndex = (yearIndex + parseInt(month) - 1) % colors.length;

    return colors[colorIndex];
  } catch (e) {
    return "white";
  }
}

function getDays(month) {
  let year = month.split("-")[0];
  let m = month.split("-")[1];
  if (m == "1")
    return 31;
  else if (m == "2") {
    if (year == "2024" || year == "2028")
      return 29;
    else
      return 28;
  }
  else if (m == "3")
    return 31;
  else if (m == "4")
    return 30;
  else if (m == "5")
    return 31;
  else if (m == "6")
    return 30;
  else if (m == "7")
    return 31;
  else if (m == "8")
    return 31;
  else if (m == "9")
    return 30;
  else if (m == "10")
    return 31;
  else if (m == "11")
    return 30;
  else if (m == "12")
    return 31;
  else
    return 31;
}

function profitMonth(value) {
  var total = 0;
  for (var i in value) {
    // console.log("Value: " + i);
    total += value[i];
  }
  return total;
}

/**
 * Used to show a small bar on the chart if the value is 0
 *
 * @type Object
 */
var showZeroPlugin = {
    beforeRender: function (chartInstance) {
        var datasets = chartInstance.config.data.datasets;

        for (var i = 0; i < datasets.length; i++) {
            var meta = datasets[i]._meta;
            // It counts up every time you change something on the chart so
            // this is a way to get the info on whichever index it's at
            var metaData = meta[Object.keys(meta)[0]];
            var bars = metaData.data;

            for (var j = 0; j < bars.length; j++) {
                var model = bars[j]._model;

                if (metaData.type === "horizontalBar" && model.base === model.x) {
                    model.x = model.base + 2;
                } else if (model.base === model.y) {
                    model.y = model.base - 2;
                }
            }
        }

    }
};

// Enabled by default
Chart.pluginService.register(showZeroPlugin);

const DaysInMonth = getDaysInMonth(Month, Year).length;
const ArrayDays = () => {
  for (var i = 0; i < DaysInMonth; i++) {
    return i + 1;
  }
};

class RevenueGraphs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      dailyseries: [],
      monthlyseries: [],
      monthlylabels:[]
    };
  }

  componentDidMount() {
    this.GetSeries();
  }

  GetSeries = async () => {
    const self = this;
    let token = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("x-parse-application-id", APP_ID);
    myHeaders.append("x-parse-rest-api-key", MASTER_KEY);
    myHeaders.append("x-parse-session-token", token);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      options: this.options,
    };

    const response = await fetch(
      "https://"+API_URL+"/functions/getdailystats",
      requestOptions
    );
    const responseJson = await response.json();
    // console.log(responseJson);
    const dailyseriesClone = [...self.state.dailyseries];
    const monthlyseriesClone = [...self.state.monthlyseries];
    const monthlylabelsClone = [...self.state.monthlylabels];
    let revMonth = [];
    // const netProfitValues = responseJson.result.NetProfit.data; // in the API response, the netProfit data is actually the Revenue
    // const revenueValues = responseJson.result.Revenue.data;
    // dailyseriesClone[0].data = revenueValues;
    // dailyseriesClone[0].data = netProfitValues;
    // let rows = [
    //   ['Month', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
    //     '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
    //     '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'
    //   ]
    // ];
    try {
    for(var key in responseJson.result) {
        var value = responseJson.result[key];
        // console.log(value);
        let operatingProfit = [];
        operatingProfit.length = getDays(key);
        operatingProfit.fill(0);
        for (var i in value.NetProfit.data) {
          operatingProfit[i - 1] = value.NetProfit.data[i];
        }
        var totalMonthValue = profitMonth(operatingProfit);
        // console.log("Month: " + key + "Profit" + totalMonthValue);
        if (totalMonthValue != 0) {
          revMonth.unshift(totalMonthValue);
          monthlylabelsClone.unshift(key);
          // rows.push(value.NetProfit.data);
          dailyseriesClone.unshift({label: key, data: operatingProfit, hidden: true, backgroundColor: colorSelector(key) });
        }
        // console.log("Key: " + key + " Value " + value);
    }
    monthlyseriesClone.push({label: "2020-2021", data: revMonth, backgroundColor: "#01c5c4" });
    if (dailyseriesClone[dailyseriesClone.length - 1])
      dailyseriesClone[dailyseriesClone.length - 1].hidden = false;
    else
      console.log("Not stats for this month yet.");
    } catch (e) {
      console.log("Error in loading data for operatingProfit: " + e);
    }

    self.setState({
      isLoading: false,
      dailyseries: dailyseriesClone,
      monthlyseries: monthlyseriesClone,
      monthlylabels: monthlylabelsClone
    });
  //   let csvContent = "data:text/csv;charset=utf-8,"
  //     + rows.map(e => e.join(",")).join("\n");
  //     var encodedUri = encodeURI(csvContent);
  //     window.open(encodedUri);
  };
  render() {
      return (
          <div style={{margin: "2rem 0"}}>
          <div>
          <h1 className="field">OPERATING PROFIT DAILY</h1>
          <div style={{backgroundColor:"#000000", borderRadius:"10px", padding:"10px"}}>
            < Bar
              data={{
                labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
                  '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
                  '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'
                ],
                datasets: this.state.dailyseries

              }}
              
              height = {
                400
              }
              width = {
                600
              }
              options = {
                {
                  maintainAspectRatio: false,
                  title: {
                        display: false,
                        text: 'Operating Profit',
                        fontSize: 32,
                        fontweight: "bold"
                    },
                    legend: {
                        display: true,
                        labels: {
                            boxWidth: 20,
                            // fontSize: 16,
                            // fontStyle: 'bold',
                            //fontColor: '000000'
                        }
                    },
                  scales: {
                        yAxes: [{
                          gridLines: {
                            color: "#191919"
                          },
                            ticks: {
                              beginAtZero: true,
                                // Include a Euro sign in the ticks
                                callback: function(value, index, values) {
                                    return value + ' €';
                                }
                            }
                        }]
                    },
                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem, data) {

                                var label = Math.round(tooltipItem.yLabel * 100) / 100;
                                label += ' €'
                                return label;
                            }
                        }
                    }
                }
              }
            />
            </div>
            </div>
            <div>
            <br/>
            <h1 className="field">OPERATING PROFIT MONTHLY</h1>
            <div style={{backgroundColor:"#000000", borderRadius:"10px", padding:"10px"}}>
            < Bar

              data={{
                labels: this.state.monthlylabels, //['August', 'September', 'October', 'November', 'December', 'January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: this.state.monthlyseries

              }}
              height = {
                400
              }
              width = {
                600
              }
              options = {
                {
                  maintainAspectRatio: false,
                  title: {
                        display: false,
                        text: 'Operating Profit',
                        fontSize: 32,
                        fontweight: "bold"
                    },
                    legend: {
                        display: false,
                        labels: {
                            boxWidth: 20,
                            // fontSize: 16,
                            // fontStyle: 'bold',
                            //fontColor: '000000'
                        }
                    },
                  scales: {
                       
                        yAxes: [{
                          gridLines: {
                            color: "#191919"
                          },
                            ticks: {
                              beginAtZero: true,
                                // Include a Euro sign in the ticks
                                callback: function(value, index, values) {
                                    return value + ' €';
                                }
                            }
                        }],
                        xAxes: [{
                          gridLines: {
                            color: "#191919"
                          },
                        }],
                    },
                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem, data) {

                                var label = Math.round(tooltipItem.yLabel * 100) / 100;
                                label += ' €'
                                return label;
                            }
                        }
                    }
                }
              }
            />
            </div>
            </div>
          </div>
      );
    }
  }

export default RevenueGraphs;
