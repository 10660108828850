import React from 'react';
import Moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import {
    withRouter
} from 'react-router-dom';
import {
    API_URL,
    APP_ID,
    MASTER_KEY,
    API_URL_ELOOP_AT
} from '../../config';
import i18n from '../../i18n';
import Vehicle from '../Ui/Vehicle';
import NewVehicle from '../Ui/NewVehicle';
import VehicleFuture from '../Ui/Vehicle_future';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
class Vehicles extends React.Component {
    constructor() {
        super();
        this.state = {
            vehicles: [],
            loading: true,
            balance: 0,
            tabId: 'basic-tab-1',
            isBooked: 0,
            isCharging: 0,
            isAvailable: 0,
            isOutOfServive: 0,
            name: "TESLA"
        };
        this.onChangeValue = this.onChangeValue.bind(this);
        momentDurationFormatSetup(Moment);
    }
    onChangeValue(event) {
      this.setState({
          name: event.target.value
      })
    }
    componentWillMount() {
        let token = localStorage.getItem('token');
        if (!token) {
            this.props.history.push('/login');
        } else {
            this.getVehicles();
        }
    }
    getVehicles = () => {
        try {
            fetch('https://' + API_URL + '/classes/Vehicle/?order=-dayUsingStarts', {
                method: 'GET',
                headers: new Headers({
                    'X-Parse-Application-Id': APP_ID,
                    'X-Parse-REST-API-Key': MASTER_KEY,
                    'Content-Type': 'application/json',
                    'X-Parse-Session-Token': localStorage.getItem('token')
                })
            }).then(response => {
                return response.json();
            }).then(data => {
              console.log(data);
                var vehicleDataList = data['results'];
                let plateArray = []
                for (let i = 0; i < vehicleDataList.length; i++) {
                  if (vehicleDataList[i].includeInRevenueCalc) // skip archived cars
                    plateArray.push(vehicleDataList[i].plate);
                }
                try {
                    fetch("https://" + API_URL_ELOOP_AT + '/eloop-one/vehicles?plate=' + JSON.stringify(plateArray), {
                        method: "GET"
                    }).then((response) => {
                        return response.json();
                    }).then((newData) => {
                      let isBooked = 0;
                      let isCharging = 0;
                      let isAvailable = 0;
                      let isOutOfServive = 0;
                        for (let b = 0; b < newData.length; b++) {
                          // console.log("newData: " + JSON.stringify(newData[b]));
                          for(let c = 0; c < vehicleDataList.length; c++) {
                            if(newData[b].plate.replace(/-/g, '') == vehicleDataList[c].plate) {
                              vehicleDataList[c].info = newData[b];
                            }
                          }
                          if (newData[b]) {
                            if ((newData[b].isBooked || newData[b].booking_status == 1) && newData[b].booking_status != 4 && newData[b].booking_status != 6)
                              isBooked++;
                            if (newData[b].isCharging && newData[b].booking_status == 4)
                              isCharging++;
                            if ((newData[b].booking_status == 4 && !newData[b].isCharging) || newData[b].booking_status == 6)
                              isOutOfServive++;
                            if (newData[b].booking_status == 0)
                              isAvailable++;
                          }
                        }
                        this.setState({
                            vehicles: vehicleDataList,
                            loading: false,
                            isBooked: isBooked,
                            isCharging: isCharging,
                            isOutOfServive: isOutOfServive,
                            isAvailable: isAvailable
                        })
                    }).catch((e) => console.log(e));
                } catch (err) {
                    console.log("Error fetching data-----------", err);
                }
                //this.setState({ vehicles: vehicleDataList, loading: false });
               // console.log(data)
            }).catch(e => console.log(e));
        } catch (err) {
            console.log('Error fetching data-----------', err);
        }
    };
    vehicleKmFormatter = cell => {
        if (cell) return Number(cell.toFixed(0));
        else return 0;
    };
    priceFormatter = cell => {
        if (cell) {
            return Number(cell).toFixed(2).toString();
        } else return 0;
    };
    durationFormatter = cell => {
        if (cell) {
            return Moment.duration(Number(cell.toFixed(0)), 'minutes').format('H:mm:ss');
        } else return 0;
    };
    render() {
        return ( < div >
          <h1 className="field text-center">EOT CAR POOL</h1>
          <Row style={{marginBottom: "2rem", textAlign: "center", color: "white"}}>
          <Col className="flex">
          <div style={{"backgroundColor": "#3879ae"}} className="field">
            <span  className="data revdata">{this.state.isBooked}</span>
            <p style={{color: "white"}} className="label">Booked</p>
          </div>
          </Col>
          <Col className="flex">
          <div style={{"backgroundColor": "#45c5c4"}} className="field">
            <span className="data revdata">{this.state.isAvailable}</span>
            <p style={{color: "white"}} className="label">Available</p>
          </div>
          </Col>
          <Col className="flex">
          <div style={{"backgroundColor": "#b79653"}} className="field">
            <span className="data revdata">{this.state.isCharging}</span>
            <p style={{color: "white"}} className="label">Charging</p>
          </div>
          </Col>
          <Col className="flex">
          <div style={{"backgroundColor": "#ca5575"}} className="field">
            <span className="data revdata">{this.state.isOutOfServive}</span>
            <p style={{color: "white"}} className="label">Out of Service</p>
          </div>
          </Col>
          </Row>
          <div style={{"color": "white"}} onChange={this.onChangeValue}>
            <input type="radio" value="TESLA" name="model"/> {"ACTIVE "}
            <input type="radio" value="BMW" name="model" /> {"ARCHIVED "}
            {/*(<input type="radio" value="ALL" name="model" /> {"ALL"})*/}
          </div>
          {( <div className = 'vehiclegrid' >
          {this.state.name == "ALL" ? this.state.vehicles.map((vehicle, index) => (< Vehicle key = {index} data = {vehicle}/>)):
            this.state.name == "TESLA"  ? this.state.vehicles.map((vehicle, index) => (vehicle.includeInRevenueCalc ? < Vehicle key = {index} data = {vehicle}/> : null))
            : this.state.vehicles.map((vehicle, index) => (vehicle.includeInRevenueCalc == false ? < Vehicle key = {index} data = {vehicle}/> : null))
          }
          < /div>)
          }
        < /div>);
    }
}
export default withRouter(Vehicles);
