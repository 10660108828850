export default {
  inputStyle: {
    borderWidth: "0",
    borderStyle: "none",
    width: "100%",
    height: 50,
    fontSize: 16,
    lineHeight: 1.33,
    paddingLeft: 20,
    paddingRight: 20,
    fontFamily: "Sofia Pro",
    maxWidth: 500,
    backgroundColor: "#e3e3e3",
  },
  txtStyle1: {
    marginTop: 10,
    fontSize: 14,
    color: "white",
  },
  txtStyle2: {
    marginTop: 50,
    fontSize: 14,
    color: "white",
    textAlign: "center",
    textTransform: "uppercase",
  },
  titleStyle: {
    
    whiteSpace: "pre-line",
    fontFamily: "Sofia Pro",
    marginTop: 20,
    marginBottom: 20,
    color: "white",
  },

 

  instructionWrap: {
    width: "80%",
    margin: "auto",
    marginBottom: 30,
    paddingTop: 40,
    paddingBottom: 40,
  },

  instructionStyle: {
    fontFamily: "Sofia Pro",
    marginTop: 10,
    marginBottom: 10,
    color: "white",
  },

  linkStyle: {
    fontFamily: "Sofia Pro",
    marginTop: 30,
    color: "#7d7d7d",
    textDecoration: "underline",
    color: "white",
    textAlign: "right",
  },

  kycButtonStyle: {
    fontFamily: "Sofia Pro",
    backgroundColor: "#4adeb5",
    color: "white",
    padding: "1em 1.5em",
    position: "relative",
    textDecoration: "none",
    fontWeight: "bold",
    marginLeft: 20,
    marginTop: -10,
  },

  walletButtonStyle: {
    fontFamily: "Sofia Pro",
    backgroundColor: "#4adeb5",
    color: "white",
    padding: "1em 1.5em",
    textDecoration: "none",
    fontWeight: "bold",
  },

  headingStyle: {
    fontFamily: "Sofia Pro",
    marginBottom: 10,
    color: "white",
  },

  inputBoxStyle: {
    marginBottom: 10,
    width: "100%",
  },

  errorStyle: {
    fontFamily: "Sofia Pro",
  },

  detailboxStyle: {
    fontFamily: "Sofia Pro",

    fontSize: 15,
    margin: " 20px 0",
  },

  detailTitleStyle: {
    fontFamily: "Sofia Pro",

    paddingTop: 10,
    fontWeight: "bold",
    fontSize: 16,
    display: "block",
    paddingBottom: 7,
    paddingLeft: 10,
    margin: " 20px auto",
    backgroundColor: "#EEE",
    maxWidth: 600,
    width: "100%",
  },

  seedPhraseStyle: {
    width: "100%"
  },

  detailItemStyle: {
    marginLeft: 15,
    color: "white",
  },

  valueTextStyle: {
    marginLeft: 15,
    fontSize: 16,
    fontWeight: "bold",
    color: "white",
  },

  pharsedetailItemStyle: {
    paddingBottom: 7,
    paddingLeft: 10,
    marginBottom: 10,
    display: "block",
    maxWidth: 600,
    width: "100%",
  },

  viewbtnStyle: {
    backgroundColor: "#4ADEB5",
    border: "none",
    height: 30,
    paddingLeft: 15,
    paddingRight: 15,
    marginLeft: 15,
    color: "white",
  },

  marginTop15: {
    marginTop: 25,
    color: "white",
    textAlign: "center",
  },

  submitStyle: {
    textAlign: "center",
    height: 60,
    border: 0,
    fontSize: 18,
    paddingLeft: 30,
    paddingRight: 30,
    backgroundColor: "#4adeb5",
    color: "#ffffff",
    width: "40%",
    marginTop: 40,
  },

  greenButtonStyle: {
    textAlign: "center",
    height: 40,
    border: 0,
    borderRadius: "5px",
    fontSize: 18,
    fontWeight: "bold",
    backgroundColor: "#4adeb5",
    color: "#ffffff",
    marginTop: 10,
    maxWidth: 200,
    width: "100%",
  },
  purpleButtonStyle: {
    textAlign: "center",
    height: 40,
    border: 0,
    borderRadius: "5px",
    fontSize: 18,
    fontWeight: "bold",
    backgroundColor: "#6d41da",
    color: "#ffffff",
    marginTop: 10,
    maxWidth: 200,
    width: "100%",
  },

  greenFullButtonStyle: {
    textAlign: "center",
    height: 50,
    border: 0,
    fontSize: 18,
    fontWeight: "bold",
    backgroundColor: "#4adeb5",
    color: "#ffffff",
    marginTop: 10,
    width: "100%",
  },

  submitAddressStyle: {
    textAlign: "center",
    height: 60,
    border: 0,
    fontSize: 18,
    paddingLeft: 30,
    paddingRight: 30,
    backgroundColor: "#4adeb5",
    color: "#ffffff",
    width: "40%",
  },

  copySeedStyle: {
    color: "#4adeb5",
  },

  seedPharse: {
    textAlign: "center",
    backgroundColor: "#f0f0f0",
    border: "1px solid #e0e0e0",
    borderRadius: "3px",
    color: "#000000",
    lineHeight: "2.5rem",
    fontSize: 18,
    padding: "15px 30px",
    wordSpacing: "20px",
  },

  textGray: {
    color: "#7d7d7d",
  },

  tab: {
    backgroundColor: "#0d0d0d",
    border: "none",
    width: 180,
    height: 60,
    color: "white",
    fontFamily: "Sofia Pro",
    fontSize: 15,
    textTransform: "uppercase",
  },

  tabSelected: {
    backgroundColor: "#3c354c",
    border: "none",
    width: 180,
    height: 60,
    color: "white",
    fontFamily: "Sofia Pro",
    fontSize: 15,
    textTransform: "uppercase",
  },

  tabPanel: {
    marginTop: 30,
  },

  buyTokenBlock: {
    width: "80%",
    margin: "auto",
    paddingLeft: 20,
    paddingRight: 20,
  },

  amountWrap: {
    width: "100%",
    paddingTop: 20,
    display: "flex",
    alignItems: "center",
  },

  equalWrap: {
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 25,
  },

  checkboxLabel: {
    display: "block",
    position: "relative",
    paddingLeft: 25,
    marginBottom: 10,
  },

  spaceTop: {
    marginTop: 20,
  },

  spaceBottom: {
    marginBottom: 20,
    color: "white",
    textAlign: "center",
  },

  blackButtonStyle: {
    textAlign: "center",
    height: 50,
    border: 0,
    fontSize: 18,
    paddingLeft: 30,
    paddingRight: 30,
    backgroundColor: "#000",
    color: "#ffffff",
    marginTop: 40,
    float: "right",
  },

  clearBoth: {
    clear: "both",
  },

  paymentButton: {
    marginRight: 40,
    backgroundColor: "transparent",
    border: "none",
  },

  paymentType: {
    width: 120,
    marginRight: 40,
  },

  headingStyle1: {
    fontWeight: "bold",
    fontSize: 20,
    marginTop: 15,
    color: "white",
  },
  smallText: {
    fontSize: 13,
    textAlign: "center",
  },
  revenueTitleStyle: {
    textTransform: "uppercase",
    opacity: ".5",
  },
  revAmountStyle: {
    fontSize: "2rem",
    color: "rgb(73, 80, 87)",
    fontWeight: "700",
  },
  completeKYCMessageStyle: {
    textTransform: "uppercase",
    color: "white",
    fontWeight: "bold",
  },
};
