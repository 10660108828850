import React from "react";
import paymentMethodsMock from "./adyen.json";
import {
  API_URL,
  APP_ID,
  ASSET_ID,
  MASTER_KEY,
  ENCRYPTION_KEY,
  TOPRICE
} from '../../config';
import CircularProgress from "@material-ui/core/CircularProgress";


import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);
class AdyenPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
    this.initAdyenCheckout = this.initAdyenCheckout.bind(this);
  }

  componentDidMount() {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href =
      "https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/3.6.0/adyen.css";
    document.head.appendChild(link);

    const script = document.createElement("script");
    script.src =
      "https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/3.6.0/adyen.js";
    script.async = true;
    script.onload = this.initAdyenCheckout; // Wait until the script is loaded before initiating AdyenCheckout
    document.body.appendChild(script);


    fetch("https://" + API_URL + "/users/me", {
        method: "GET",
        headers: new Headers({
          "X-Parse-Application-Id": APP_ID,
          "X-Parse-REST-API-Key": MASTER_KEY,
          "Content-Type": "application/json",
          "X-Parse-Session-Token": localStorage.getItem('token'),
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.setState({ ...data });
        });

  }

  initAdyenCheckout() {
    const configuration = {
      locale: "de-DE",
      environment: "live",
      originKey: "pub.v2.2615454035882888.aHR0cHM6Ly9kYXNoYm9hcmQuZWxvb3Aub25l.7DSM-vl-PR69DdYMytly9KW7ovTCUKdv37VL75OQTws",
      paymentMethodsResponse: {
        "paymentMethods": [
           {
            "name": "CreditCard",
            "supportsRecurring": false,
            "type": "scheme"
           }
        ]
      },
      amount: {
        value: (this.props.amount * 100),
        currency: "EUR"
      }
    };

    const checkout = new window.AdyenCheckout(configuration);
    
    checkout
      .create("dropin", {
        paymentMethodsConfiguration: {
           card: { // Sample Drop-in configuration for 3D Secure
               hasHolderName: true,
               holderNameRequired: true,
               enableStoreDetails: true,
               name: 'Credit or debit card',
               billingAddressRequired: true
           },
        
       },
        onSubmit: (state, dropin) => {
          state.data.origin = window.location.href;
          state.data.shopperEmail = this.state.email
          this.setState({ loading: true });

        fetch('https://' + API_URL + '/functions/adyenpayment', {
        method: 'POST',
        headers: new Headers({
          'X-Parse-Application-Id': APP_ID,
          'X-Parse-REST-API-Key': MASTER_KEY,
          'Content-Type': 'application/json',
          'X-Parse-Session-Token': localStorage.getItem('token'),
        }),
        body: JSON.stringify({amount: configuration.amount, initData: state.data})
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {

           if(data.result.status) {
            this.setState({ loading: false });
            //localStorage.setItem("_ed_token_", configuration.amount);

            var tokenValue =  this.props.token + "###" + this.props.token + "###" + "Sofort";
            tokenValue = window.btoa(tokenValue);
            localStorage.setItem("_ed_token_", tokenValue);
            
           
            if(data.result.message == "Redirect") {
              localStorage.removeItem("_adyenpaymentdata_");
              localStorage.setItem("_adyenpaymentdata_", window.btoa(data.result.action.paymentData));
              dropin.handleAction(data.result.action);
            } else {
              window.location.href = data.result.redirectUrl;
            }



           } else {
            this.setState({ loading: false });
             MySwal.fire({
              icon: "error",
              text: "Purchase unsucessfull ("+data.result.message+")" //i18n.t("minimum purchase of token is 100!"),
            }).then(() => {
              window.location.href = "/buy"
            });
            
           }

        })
        .catch((err) => console.log(err));

        },
        onAdditionalDetails: (state, dropin) => {
          // Your function calling your server to make a `/payments/details` request
         
        } 

      })
      .mount(this.ref);
  }

  render() {
    return (
      <div>
      {this.state.loading && (
          <div className="loading-body">
            <CircularProgress />
          </div>
        )}
      <div
        ref={ref => {
          this.ref = ref;
        }}
      />
      </div>
    );
  }
}

export default AdyenPayment;

