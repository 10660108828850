import React from "react";
import styled from "@emotion/styled";
import Bmw from "../../assets/images/eloop-bmw.png";
import Tesla from "../../assets/images/model3_april.png";
import TeslaY from "../../assets/images/modely_april.png";
import Moment from "moment";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
// import momentDurationFormatSetup from 'moment-duration-format';
import i18n from "../../i18n";
const prettyMilliseconds = require('pretty-ms');



// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   background-color: white;
//   padding: 1.25em 0.25em;
//   box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
//     0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
//     0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
//     0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
//
//   border: 1px solid rgba(32, 39, 140, 0.125);
//   border-radius: 0.25rem;
// `;
const Active = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 25px;
`;
const Plate = styled.div`
  background-color: transparent;
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
`;
const Span = styled.span`
  font-weight: 500;
  text-transform: uppercase;
  padding: 6px !important;
  height: auto;
  min-width: 19px;
  color: #fff;
  background-color: #45C5C4;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 70%;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: initial;
  border-radius: 100px;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;
const SecondRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  div p {
    font-size: 0.7em;
    text-transform: uppercase;
    text-align: center;
    opacity: 0.5;
  }
  div span {
    font-weight: bold;
  }
`;
const ThirdRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  div p {
    font-size: 0.7em;
    text-transform: uppercase;
    text-align: center;
    opacity: 0.5;
  }
  div span {
    font-weight: bold;
  }
`;

const durationFormatter = (cell) => {
  if (cell) {
    return Moment.duration(Number(cell.toFixed(0)), "minutes").format(
      "H"
    );
  } else return 0;
};

const drivingFor = (duration) => {
  // cuts of seconds;
  let time = duration.split("m");
  time = time[0];
  return time + "M";
};

const daysInFleet = (duration) => {
  // cuts off min and seconds
  let time = duration.split("d");
  time = time[0];
  return time + " d";
};

const tokenizedSince = (licensePlate, startDate) => {
    var date = new Date(startDate);
    if (!isNaN(date.getTime())) {
      var day = date.getDate().toString();
      var month = (date.getMonth() + 1).toString();
      // Months use 0 index.
      return (
        (day[1] ? day : "0" + day[0]) +
        "." +
        (month[1] ? month : "0" + month[0]) +
        "." +
        date.getFullYear()
      );
    }
    return "";
};

const revPerCarConst = (data) => {
  let lastDay = new Date();
  if (data.data.finishedUsingTrips) {
    lastDay = new Date(data.data.finishedUsingTrips.iso);
    // console.log(lastDay);
  }
  let costPerCar = (Math.round(Math.abs((lastDay - Date.parse(data.data.dayUsingStarts.iso)) / (24 * 60 * 60 * 1000)))-1)*((data.data.monthlyCostEur*12)/360)
  var revPerCar = (data.data.totalRevenue-(costPerCar)).toFixed(2);
  var colorForRevPerCar = "green";
  if(revPerCar < 0) { colorForRevPerCar = "red" }
  return {revPerCar: revPerCar,colorForRevPerCar: colorForRevPerCar, costPerCar: costPerCar}
}

const roi = (data) => {
  let profitsplit = 0.5;
  let tokenprice = 1.1;
  let investment = data.totalCostEt * tokenprice;
  let days = Math.floor((new Date - Date.parse(data.dayUsingStarts.iso)) / 86400000); // ms divided by 8,64e+7 to get days
  // console.log(days);
  let totalProfit = data.totalRevenue - ((Math.round(Math.abs((new Date - Date.parse(data.dayUsingStarts.iso)) / (24 * 60 * 60 * 1000)))-1)*((data.monthlyCostEur*12)/360));
  let roi = (profitsplit * (365 / days) * totalProfit / investment * 100).toFixed(2);
  if (roi > 5)
    return ("💎 " + roi);
  else
    return roi;
}

const carImage = (data) => {
  if(data.data.brand.includes('Model 3')) { return Tesla; } else if (data.data.brand.includes('Model Y')) { return TeslaY } else { return Bmw; }
}


const Vehicle = (data) => (

  <Container fluid={true} style={{backgroundColor:"#191919", "color": "white", marginTop:"1rem"}}>
  {/*<Accordion>
    <Accordion.Toggle as={Card.Header} eventKey="0" className="tripHeader" style={{"backgroundColor": "white"}}>*/}
  <Row className="text-center justify-between ">
    <Col>
    <div className="cardetails">
    <Plate>{data.data.plate}</Plate>
    <Col>
      <div className="textvehicle">
        {data.data.info != undefined && data.data.info.isBooked != undefined && data.data.info.isBooked && data.data.info.isBookedByUser && (data.data.info.booking_status == 2 || data.data.info.booking_status == 1) && data.data.includeInRevenueCalc ? (
          <>
            {/* <div style={{backgroundColor: "rgb(0,145,124)"}} className="dot">
              <div style={{backgroundColor: "rgb(0,145,124)"}} className="dot-pulse"/>
            </div> */}
            <Span style={{"backgroundColor": "#3879AE", color:"#fff"}}>{i18n.t("Booked by User")}</Span>
            <Span style={{display: "block", "backgroundColor": "#191919", color: "#fff", "paddingLeft": "0px" }}>{i18n.t("Driving for")} {
              data.data.info.bookedSince != undefined && data.data.info.bookedSince > 0 && data.data.info.start_date != undefined
                ? drivingFor(prettyMilliseconds(new Date() - Date.parse(data.data.info.start_date)))
                : (<>N/A</>)
            }</Span>
          </>
        )
         : (data.data.info != undefined && data.data.info.isBooked != undefined && data.data.info.booking_status == 2) && data.data.includeInRevenueCalc ? (
          <>
            <div style={{backgroundColor: "rgb(0,145,124)"}} className="dot">
              <div style={{backgroundColor: "rgb(0,145,124)"}} className="dot-pulse" />
            </div>
            <Span style={{backgroundColor: "rgb(0,145,124)"}}>{i18n.t("Reserved")}</Span>
            <Span style={{display: "block", "backgroundColor": "#000", color: "black", "paddingLeft": "0px" }}>-</Span>
          </>
        )
        : (data.data.info != undefined && data.data.info.isBooked != undefined && ((data.data.info.isBooked && !data.data.info.isBookedByUser) || data.data.info.booking_status == 4 || data.data.info.booking_status == 6)) && data.data.includeInRevenueCalc ? (
          <>
            {/* {data.data.info.isCharging ? <div style={{backgroundColor: "rgb(2,120,174)"}} className="dot">
              <div style={{backgroundColor: "rgb(2,120,174)"}} className="dot-pulse" />
            </div> :
            <div style={{backgroundColor: "rgb(236,70,70)"}} className="dot">
              <div style={{backgroundColor: "rgb(236,70,70)"}} className="dot-pulse" />
            </div>
            } */}
            {data.data.info.isCharging ?
            <Span style={{backgroundColor: "#B79653", color:"#fff"}}>{i18n.t("Charging")}</Span> :
            <Span style={{"backgroundColor": "#CA5575", color:"#fff"}}>{i18n.t("Out of Service")}</Span>}
            <Span style={{display: "block", "backgroundColor": "#191919", color: "black", "paddingLeft": "0px" }}></Span>
          </>
        )
         : (data.data.includeInRevenueCalc) ? (
          <>
           
            <Span>{i18n.t("Available for Booking")}</Span>
          
          </>
        )
        : (
          <div className="cardetails">

          <Plate>ARCHIVED</Plate>
          <Span style={{color: "#ffffff",backgroundColor:"transparent"}}>{tokenizedSince(data.data.plate, data.data.finishedUsingTrips.iso)}</Span>
         </div>
       )}
        <div>
        {(data.data.includeInRevenueCalc && data.data.info != undefined) ? <Span style={{display: "block", "backgroundColor": "#191919", color: "#fff", "paddingLeft": "0px" }}>{data.data.info != undefined && data.data.info.isCharging ? ( <>⚡ { data.data.info.autonomy} km left</> ) : ( <>{ data.data.info.autonomy} km left</> )}</Span>
        : null}
        </div>
      </div>
    </Col>
    <Span style={{color: "#ffffff", backgroundColor:"transparent"}}>{tokenizedSince(data.data.plate, data.data.dayUsingStarts.iso)}</Span>
    </div>
    </Col>
    <div className="vehicle-img-wrapper">
    <img
    src={carImage(data)}
    alt="Eloop Bmwi3"
          className="img-vehicle"
  />
  </div>
  </Row>
  {/*</Accordion.Toggle>
  <Accordion.Collapse eventKey="0">*/}
  <Row className="text-center">
    <Col>
    <div className="field">
      <span className="data">{data.data.totalCostEt} EOT</span>
      <p className="label">Value</p>
    </div>
    <div className="field">
      <span className="data">{data.data.monthlyCostEur} €</span>
      <p className="label">Cost per Month</p>
    </div>
    <div className="field">
      <span className="data">{Math.round(data.data.totalKm)} km</span>
      <p className="label">KM Driven</p>
    </div>
    <div className="field">
      <span className="data">{Math.ceil(data.data.totalKm * 3.3 * 0.165)} kg</span>
      <p className="label">
        CO<sub>2</sub> Saved
      </p>
    </div>
    {/*<div className="field">
      <span className="data">{daysInFleet(prettyMilliseconds(new Date() - Date.parse(data.data.dayUsingStarts.iso)))}</span>
      <p className="label">Days in EOT Fleet</p>
    </div>*/}
    </Col>
    <Col>
    <div className="field">
      <span className="data">{Math.trunc(data.data.totalRevenue)} €</span>
      <p className="label">Total Revenue</p>
    </div>
    <div className="field">
      <span className="data">{Math.trunc(revPerCarConst(data).costPerCar)} €</span>
      <p className="label">Total Cost</p>
    </div>
    <div className="field">
      <span className="data" style={{color: revPerCarConst(data).colorForRevPerCar}}>{ Math.trunc(revPerCarConst(data).revPerCar) } €</span>
      <p className="label">Total Profit</p>
    </div>
    <div className="field">
      <span className="data">{durationFormatter(data.data.totalTime)} h</span>
      <p className="label">Hours Used</p>
    </div>
    {/*<div className="field">
      <span className="data">{roi(data.data)}%</span>
      <p className="label">ROI / year *</p>
    </div>*/}
    </Col>
  </Row>
{/*</Accordion.Collapse>
</Accordion>*/}
 
  </Container>
);

export default Vehicle;
