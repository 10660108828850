import React from "react";
import {
  Line, defaults, Chart, Radar, Doughnut, Bar, Pie
} from "react-chartjs-2";

Chart.defaults.global.defaultFontColor = 'white';

function decColor(profit) {
  if (profit > 0)
    return 'rgba(75, 192, 192, 1)';
  else {
    return 'red';
  }
}

const CostBreakDown = () => {
  return <div style={{margin: "12px"}}>
    <
    Pie
  data = {
    {
      datasets: [
      {
        data: [2, 16, 49, 15, 18],
        backgroundColor: [
        'rgba(255, 99, 132, 0.7)',
        'rgba(54, 162, 235, 0.6)',
        'rgba(255, 206, 86, 0.6)',
        'rgb(0,145,124, 0.5)',
        "rgb(1, 197, 196, 0.8)"
      ],
      borderColor: [
        'rgba(255,99,132,1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgb(0,145,124, 1)',
        "rgb(1, 197, 196, 1)"
      ],
      borderWidth: 1


      }
    ],
    labels: ['Parking', 'Operations', 'Insurance', 'Energy', 'Hardware, Software & Licenses'],
    }
  }
  height = {
    400
  }
  width = {
    600
  }
  options = {
    {
      maintainAspectRatio: false,
      title: {
            display: true,
            text: 'Cost Breakdown',
            fontSize: 24,
            fontweight: "bold"
        },
      legend: {
            display: true,
            labels: {
                boxWidth: 15
            }
      },
      tooltips: {
          enabled: true,
          callbacks: {
            label: function(tooltipItem, data) {
                //get the concerned dataset
                var dataset = data.datasets[tooltipItem.datasetIndex];
                //calculate the total of this data set
                var total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
                  return previousValue + currentValue;
                });
                //get the current items value
                var currentValue = dataset.data[tooltipItem.index];
                //calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
                var percentage = Math.floor(((currentValue/total) * 100)+0.5);

                return percentage + "%";
              }
          }
      }
    }
  }/>
  </div>
}

export default CostBreakDown;
