import React from 'react';
import { render } from 'react-dom';
import Router from './components/Router';
import './i18n';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';

/** referall **/
var ref = "";
try {
	ref = decodeURIComponent(window.location.search.match(/(\?|&)ref\=([^&]*)/)[2]);
	if(ref != "" && document.cookie.indexOf('ref') == -1 ) {
		document.cookie = "ref="+ref+";expires="+new Date(new Date().getTime()+60*60*1000*24).toGMTString()+";path=/";
	}
} catch (ex) {

}


render(
  <I18nextProvider i18n={i18next}>
    <Router />
  </I18nextProvider>,
  document.querySelector('#main-0bs-div')
);
