import React from "react";
import styles from "../../styles/styles";
import i18n from "../../i18n";
import {
  NODE_URL,
  API_URL,
  APP_ID,
  MASTER_KEY,
  ASSET_ID,
  CURRENCY_CODE_HTML,
} from "../../config";
import { withRouter } from "react-router-dom";
import Cashout from "./../Modal/Cashout";
import styled from "@emotion/styled";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CostBreakDown from "../Charts/CostBreakDown";
import RevenueBreakdown from "../Charts/RevenueBreakdown";
import RevenueGraphs from "../Charts/RevenueGraphs";
import GlobalStats from "../Charts/GlobalStats";
import CostsChart from "../Charts/Costs";
// import Co2Chart from "../Charts/Co2";
// import YearlyChart from "../Charts/Yearly";
// import Container from "../Ui/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

const MySwal = withReactContent(Swal);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 1em;
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const FiftyFifty = styled.div`
  width: 50%;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

class Revenue extends React.Component {
  constructor() {
    super();
    this.state = {
      walletaddress: "",
      tokenBalance: 0,
      totalSupply: 0,
      eurEarnings: 0,
      eurRevenue: 0,
      totalEurEarnings: 0,
      totalRevenue: 0,
      loading: true,
      tabId: "basic-tab-1",
      monthlyCostEur: 0,
      balance: 0,
      earningsLastUpdateDateTime: "",
      totalEarnings: 0,
      revenueThisMonth: 0,
      totalCashout: 0,
      revBreakdown: []
    };
  }

  componentWillMount() {
    let token = localStorage.getItem("token");
    if (!token) {
      this.props.history.push("/login");
    } else {
      fetch("https://" + API_URL + "/users/me", {
        method: "GET",
        headers: new Headers({
          "X-Parse-Application-Id": APP_ID,
          "X-Parse-REST-API-Key": MASTER_KEY,
          "Content-Type": "application/json",
          "X-Parse-Session-Token": token,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error && data.code === 209) {
            this.logOut();
          }
          this.setState({
            walletaddress: data.walletaddress ? data.walletaddress : "",
            totalEurEarnings: data.eur_earnings ? data.eur_earnings : 0,
            eurEarnings: data.current_week_earnings
              ? data.current_week_earnings
              : 0,
            balance: data.balance ? data.balance : 0,
            earningsLastUpdateDateTime: data.EarningsLastUpdateDateTime
              ? data.EarningsLastUpdateDateTime
              : "",
            userpercentagerevenue: data.currentPeriodTokenHoursPct,
          });

          fetch(
            NODE_URL+"/assets/balance/" +
              this.state.walletaddress +
              "/" +
              ASSET_ID
          )
            .then((response) => {
              return response.json();
            })
            .then((balance) => {
              if (balance.balance) {
                this.setState({
                  tokenBalance: balance.balance / 100,
                });
              } else {
                this.setState({ tokenBalance: 0 });
              }
            });
        })
        .catch((err) => {
          console.log(err);
        });

      fetch("https://" + API_URL + "/classes/Settings", {
        method: "GET",
        headers: new Headers({
          "X-Parse-Application-Id": APP_ID,
          "X-Parse-REST-API-Key": MASTER_KEY,
          "Content-Type": "application/json",
          "X-Parse-Session-Token": token,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            totalSupply: data.results[0].TotalSupply,
            totalEurRevenue: data.results[0].revenuePoolTotal.toFixed(2),
            totalCostEur: data.results[0].costPoolTotal.toFixed(2),
            revBreakdown: [data.results[0].revenuePoolTotal.toFixed(2), data.results[0].costPoolTotal.toFixed(2)]
          });
        })
        .catch((err) => console.log(err));

      fetch("https://" + API_URL + "/functions/getmystats", {
        method: "POST",
        headers: new Headers({
          "X-Parse-Application-Id": APP_ID,
          "X-Parse-REST-API-Key": MASTER_KEY,
          "Content-Type": "application/json",
          "X-Parse-Session-Token": token,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.setState({
            daysSinceStart: data.result.daysSinceStart,
            totalEarnings: data.result.totalEarnings,
            revenueThisMonth: data.result.revenueThisMonth,
            totalCashout: data.result.totalCashout,
            proRataProfit: data.result.proRataProfit,
            proRataCosts: data.result.proRataCosts,
          });
        })
        .catch((err) => console.log(err));
    }
  }

  getData = () => {};

  // processCaseOut = (cashoutAmount) => {
  //   let token = localStorage.getItem("token");
  //   if (!token) {
  //     this.props.history.push("/login");
  //   }
  //   if (cashoutAmount < 10) {
  //     MySwal.fire({
  //       icon: "warning",
  //       text: i18n.text("Minimum payout is 10 Euro !"),
  //     });
  //     return false;
  //   }
  //   if (cashoutAmount > this.state.totalEarnings - this.state.totalCashout) {
  //     MySwal.fire({
  //       icon: "warning",
  //       text: i18n.t("Not enough balance to cashout !"),
  //     });
  //     return false;
  //   }
  //   if (
  //     cashoutAmount >= 10 &&
  //     this.state.totalEarnings - this.state.totalCashout >= cashoutAmount
  //   ) {
  //     fetch("https://" + API_URL + "/functions/requestPayout", {
  //       method: "POST",
  //       headers: new Headers({
  //         "X-Parse-Application-Id": APP_ID,
  //         "X-Parse-REST-API-Key": MASTER_KEY,
  //         "Content-Type": "application/json",
  //         "X-Parse-Session-Token": token,
  //       }),
  //       body: JSON.stringify({
  //         amount: cashoutAmount,
  //       }),
  //     })
  //       .then((res) => res.json())
  //       .then((data) => {
  //         if (data.error && data.code === 209) {
  //           this.logOut();
  //         }
  //         console.log(data);
  //         MySwal.fire({
  //           icon: "success",
  //           text: i18n.t(
  //             "Your request has been sent and will be proccessed in the next 5 business days."
  //           ),
  //         });
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //     return true;
  //   } else {
  //     MySwal.fire({
  //       icon: "warning",
  //       text: i18n.t("Something went wrong! Please try again later."),
  //     });
  //   }
  // };
  //
  // processCreditConvert = (convertAmount) => {
  //   let token = localStorage.getItem("token");
  //   if (!token) {
  //     this.props.history.push("/login");
  //   }
  //   if (convertAmount < 10) {
  //     MySwal.fire({
  //       icon: "warning",
  //       text: i18n.t("Minimum 10 credit require to convert !"),
  //     });
  //     return false;
  //   }
  //   if (convertAmount > this.state.balance) {
  //     MySwal.fire({
  //       icon: "warning",
  //       text: i18n.t("Not enough balance for convert to credit !"),
  //     });
  //     return false;
  //   }
  //   if (convertAmount >= 10 && this.state.balance >= convertAmount) {
  //     fetch("https://" + API_URL + "/functions/requestCredits", {
  //       method: "POST",
  //       headers: new Headers({
  //         "X-Parse-Application-Id": APP_ID,
  //         "X-Parse-REST-API-Key": MASTER_KEY,
  //         "Content-Type": "application/json",
  //         "X-Parse-Session-Token": token,
  //       }),
  //       body: JSON.stringify({
  //         amount: convertAmount,
  //       }),
  //     })
  //       .then((res) => res.json())
  //       .then((data) => {
  //         if (data.error && data.code === 209) {
  //           this.logOut();
  //         }
  //         console.log(data);
  //         MySwal.fire({
  //           icon: "success",
  //           text: i18n.t(
  //             "Your request has been sent and will be proccessed in the next 5 business days."
  //           ),
  //         });
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //     return true;
  //   } else {
  //     MySwal.fire({
  //       icon: "warning",
  //       text: i18n.t("Something went wrong! Please try again later"),
  //     });
  //   }
  // };

  logOut = () => {
    localStorage.removeItem("token");
    this.props.history.push("/login");
  };

  render() {
    return (
      <div style={{ textAlign: "center", padding: "1% 2%" }}>
          <Container fluid={true} >
          <h1 className="field">MY BALANCE</h1>
          <Row>
          <Col className="flex">
          <div className="field">
            <span className="data revdata">{this.state.proRataProfit} €</span>
            <p className="label">REVENUE</p>
          </div>
          </Col>
          <Col className="flex">
          <div className="field">
            <span className="data revdata">{this.state.proRataCosts} €</span>
            <p className="label">COST</p>
          </div>
          </Col>
          <Col className="flex">
          <div className="field">
            <span className="data revdata">{(
              this.state.proRataProfit - this.state.proRataCosts
            ).toFixed(2)} €
            </span>
            <p className="label">OPERATING PROFIT</p>
          </div>
          </Col>
          <Col className="flex">
          <div className="field ">
            <span className="data  revdata">{this.state.totalEarnings > 0
              ? this.state.totalEarnings
              : 0} €
            </span>
            <p className="label ">MY PROFIT</p>
          </div>
          </Col>
          </Row>
          {/*<Col>
          <div className="field">
            <span className="data revdata">{(this.state.totalEarnings - this.state.totalCashout > 0
              ? this.state.totalEarnings - this.state.totalCashout
              : 0
            ).toFixed(2)} €
            </span>
            <p className="label">CASHOUT BALANCE</p>
          </div>
          <Cashout
            show={true}
            handleCaseOut={this.processCaseOut}
            handleCreditConvert={this.processCreditConvert}
          />
          </Col>*/}
          <RevenueGraphs />
          {/*<p className="info">If the revenue is less than the cost per day the loss for this day is evenly split with ELOOP and the Token Holder. That is why on some days the profit is negative.</p>

          <RevenuePerMonth />*/}
          <h1 className="field">EOT CAR POOL</h1>
          <Row style={{marginBottom: "2rem"}}>
          <Col className="flex">
          <div className="field">
            <span className="data revdata">23</span>
            <p className="label">CARS IN POOL</p>
          </div>
          </Col>
          <Col className="flex">
          <div className="field flex-row">
            <span className="data revdata">{Math.round(this.state.totalEurRevenue)} €</span>
            <p className="label">REVENUE</p>
          </div>
          </Col>
          <Col className="flex">
          <div className="field">
            <span className="data revdata">{Math.round(this.state.totalCostEur)} €</span>
            <p className="label">COST</p>
          </div>
          </Col>
          <Col className="flex">
          <div className="field">
            <span className="data revdata">{Math.round(this.state.totalEurRevenue - this.state.totalCostEur)} €</span>
            <p className="label">PROFIT</p>
          </div>
          </Col>
          </Row>

        <h1 className="field">REVENUE & COST BREAKDOWN</h1>
        {/* add gap to children */}
      <Row className="gap-2"  xs={1} md={2} lg={3}>
        <Col className="flex field">
        <RevenueBreakdown data={this.state.revBreakdown} />
        </Col>
        <Col className="flex field">
        <CostBreakDown />
        </Col>
          {/*<div className="profitcard">
            <span style={styles.revenueTitleStyle}>
              {i18n.t("My Profit today")}
            </span>
            <span style={styles.revAmountStyle}>
              0.01
              <span className="revcode"> {CURRENCY_CODE_HTML} </span>
            </span>
            <span>Total Profit after split with ELOOP</span>
          </div>*/}
          {/*<div className="profitcard">
            <span style={styles.revenueTitleStyle}>
              {i18n.t("My Profit all time")}
            </span>
            <span style={styles.revAmountStyle}>
              {this.state.totalEarnings > 0
                ? this.state.totalEarnings
                : 0}
              <span className="revcode"> {CURRENCY_CODE_HTML} </span>
            </span>
            <span>Total Profit after split with ELOOP</span>
          </div>*/}

        </Row>
            {/* <div className='revenue-box'>
                <div className='card'>
                  <span style={styles.revenueTitleStyle}>
                    {i18n.t('Monthly Costs')}
                  </span>
                  <span style={styles.revAmountStyle}>
                    <span className='revcode'>{CURRENCY_CODE_HTML} </span>
                    {this.state.monthlyCostEur}
                  </span>
                </div>
              </div> */}

            {/*  <div className='lastupdate'>
                <span style={styles.detailTitleStyle}>
                  {i18n.t('Last Updated')}
                </span>
                <span style={styles.detailItemStyle}>
                  {this.state.earningsLastUpdateDateTime}
                </span>
              </div> */}

            {/* <p style={styles.instructionStyle}>
              {' '}
              {i18n.t('Below are details of your revenue, and total revenue')}
            </p>

            <p style={styles.headingStyle1}> {i18n.t('My Share')}</p>
            */}
            <GlobalStats />
          </Container>
      </div>
    );
  }
}

/* Current Yield div className="revenue-box" >
              <div className="card">
                <span style={styles.revenueTitleStyle}>
                  {i18n.t("Current Yield")}
                </span>
                <span style={styles.revAmountStyle}>
                <span>
                {((this.state.totalEarnings/this.state.daysSinceStart)*360).toFixed(2)}
                </span>
                  <span className="revcode">{CURRENCY_CODE_HTML} </span>

                  { ((((this.state.totalEarnings/this.state.daysSinceStart)*360)/this.state.tokenBalance)*100).toFixed(2) }

                </span>
                <span>Your current Yield is based on your Performance</span>
               <Cashout
                  show={true}
                  handleCaseOut={this.processCaseOut}
                  handleCreditConvert={this.processCreditConvert}
                />
              </div>
            </div>*/

export default withRouter(Revenue);
