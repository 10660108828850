import React from "react";
import {
  Line, defaults, Chart, Radar, Doughnut, Bar, Pie
} from "react-chartjs-2";

Chart.defaults.global.defaultFontColor = 'white';

function decColor(profit) {
  if (profit > 0)
    return 'rgba(75, 192, 192, 1)';
  else {
    return 'red';
  }
}

export default class RevenueBreakdown extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   data: 0
    // };
  }
  render() {
  return <div style={{margin: "12px"}}>
  <
  Pie
  data = {
    {
      datasets: [
        {
          data: [this.props.data[1] / this.props.data[0], ((this.props.data[0] - this.props.data[1]) / this.props.data[0]) / 2, ((this.props.data[0] - this.props.data[1]) / this.props.data[0]) / 2],
          backgroundColor: [
            'rgba(255, 99, 132, 0.7)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(54, 162, 235, 0.6)'
          ],
          borderColor: [
            'rgba(255,99,132,1)',
            'rgba(75, 192, 192, 1)',
            'rgba(54, 162, 235, 1)'
          ],
          borderWidth: 1
        }
      ],
      labels: ['Cost', 'Eloop Profit', 'My Profit'],
    }
  }
  height = {
    400
  }
  width = {
    600
  }
  options = {
    {
      maintainAspectRatio: false,
      title: {
        display: true,
        text: 'Revenue Breakdown',
        fontSize: 24,
        fontweight: "bold"
      },
      legend: {
        display: true,
        labels: {
          boxWidth: 15
        }
      },
      tooltips: {
          enabled: true,
          callbacks: {
            label: function(tooltipItem, data) {
                //get the concerned dataset
                var dataset = data.datasets[tooltipItem.datasetIndex];
                //calculate the total of this data set
                var total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
                  return previousValue + currentValue;
                });
                //get the current items value
                var currentValue = dataset.data[tooltipItem.index];
                //calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
                var percentage = ((currentValue/total) * 100).toFixed(2);

                return percentage + "%";
              }
          }
      }
    }
  }
  />
  </div>
  }
}
