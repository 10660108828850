import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import customStyles from "../../styles/styles";
import i18n from "../../i18n";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default class Cashout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      valueTokens: 0,
      valueEur: 0,
      valueNetEuro: 0,
    };
  }

  changeTokenValue = (evt, type) => {
    const re = /^\d*\.?\d*$/;
    if (evt.target.value === "" || re.test(evt.target.value)) {
      var value = evt.target.value;

      if (type === "eur") {
        console.log("Value: " + value);
        this.setState({
          valueEur: value,
          valueTokens: ((value * ((100-27.5)/100)) * 2).toFixed(2),
          valueNetEuro: (value * ((100-27.5)/100)).toFixed(2),
        });
      } else {
        this.setState({
          valueTokens: value,
          //valueEur: value,
          //valueNetEuro: (value / 1.275).toFixed(2)
        });
      }
    }
  };

  handleClickOpen = () => {
    this.setState({ show: true });
  };
  handleClose = () => {
    this.setState({ show: false });
  };

  processCaseOut = () => {
    if (this.props.handleCaseOut(this.state.valueEur))
      this.setState({ show: false });
  };
  processCreditConvert = () => {
    if (this.props.handleCreditConvert(this.state.valueEur)) // this.state.valueTokens was the wrong value
      this.setState({ show: false });
  };
  chooseCashoutAmount = (value) => {
    this.setState({
      valueEur: value,
      valueTokens: ((value * ((100-27.5)/100)) * 2).toFixed(2),
      valueNetEuro: (value * ((100-27.5)/100)).toFixed(2),
    });
  };

  render() {
    return (
      <div>
        <button className="cashoutbutton" onClick={this.handleClickOpen}>
          {i18n.t("Cash Out")}
        </button>

        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.show}
        >
          <DialogContent className="cashoutModal" style={{textAlign: "center"}}>
          {/*console.log("Token Value: " + this.state.valueTokens + "\n\nEUR Value: " + this.state.valueEur)*/}
          <Container>

          <h4>CASH OUT</h4>
          <Row>
          <Col className="flex">
          <div className="field">
            <span className="data">
            10 €
            </span>
            <p className="label">Minimum</p>
          </div>
          <div className="field">
            <span className="data">
            ALL
            </span>
            <p className="label">Maximum</p>
          </div>
          </Col>
          <Col className="flex">
          <div className="field">
            <span className="data">
            27.5%
            </span>
            <p className="label">KESt</p>
          </div>
          <div className="field">
            <span className="data">
            x 2
            </span>
            <p className="label">Credits Bonus</p>
          </div>
          </Col>
          </Row>
          <hr />
            {/*<h4 className="text-center">{i18n.t("Cash Out Your Earnings")}</h4>
            <p className="text-center pl-2 pr-2">{i18n.t("cashout_text_1")}</p>
            <h4 className="text-center pb-3">{i18n.t("Cash Out Amount")}</h4>*/}
          <h4>AMOUNT</h4>
            <Row>
            {/*<input
              value={this.state.valueEur}
              name="valueEur"
              className="inputStyle text-right"
              type="text"
              autoComplete="off"
              onChange={(evt) => this.changeTokenValue(evt, "eur")}
              placeholder={i18n.t("00")}
            />*/}
            <Col className="flex">
            <div className="field" onClick={() => this.chooseCashoutAmount(10)} style={{borderStyle: "solid", borderWidth: "1px"}}>
              <span className="data">
              10 €
              </span>
            </div>
            <div className="field" onClick={() => this.chooseCashoutAmount(50)} style={{borderStyle: "solid", borderWidth: "1px"}}>
              <span className="data">
              50 €
              </span>
            </div>
            </Col>
            <Col className="flex">
            <div className="field" onClick={() => this.chooseCashoutAmount(100)} style={{borderStyle: "solid", borderWidth: "1px"}}>
              <span className="data">
              100 €
              </span>
            </div>
            <div className="field" onClick={() => this.chooseCashoutAmount(this.props.cashoutBalance)} style={{borderStyle: "solid", borderWidth: "1px"}}>
              <span className="data">
              ALL
              </span>
            </div>
            </Col>
            </Row>
            <hr />
            <h4>OPTIONS</h4>
            <Row>
            {/*<input
              value={this.state.valueEur}
              name="valueEur"
              className="inputStyle text-right"
              type="text"
              autoComplete="off"
              onChange={(evt) => this.changeTokenValue(evt, "eur")}
              placeholder={i18n.t("00")}
            />*/}
            <Col className="flex">
            <div className="field">
              <span className="data">
              {this.state.valueNetEuro} €
              </span>
              <p className="label">CASH (NET)</p>
            </div>
            <button
              type="button"
              onClick={this.processCaseOut}
              style={customStyles.purpleButtonStyle}
            >
              CASH OUT{/*i18n.t("Cash Out")*/}
            </button>
            </Col>
            <Col className="flex">
            <div className="field">
              <span className="data">
              {this.state.valueTokens} €
              </span>
              <p className="label">ELOOP Credits</p>
            </div>
            <button
              type="button"
              onClick={this.processCreditConvert}
              style={customStyles.greenButtonStyle}
            >
              CONVERT{/*i18n.t("Convert")*/}
            </button>
            </Col>
            </Row>
            <hr />
            <p className="field" style={customStyles.smallText}>
            Cash outs will be proccessed at the 15th of the next month.
              {/*i18n.t(
                "We will process your request within 5 business days."
              )*/}
            </p>
            </Container>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
