import React from "react";
import {
  API_URL,
  APP_ID,
  ASSET_ID,
  MASTER_KEY,
  NODE_URL,
  ONLYBUYELOOP,
  TOPRICE,
} from "../../config";
import styles from "../../styles/styles";
import i18n from "../../i18n";
import Modal from "../Modal/Modal";
import { toast /*ToastContainer*/ } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import CoinLogo from "../../assets/images/EOT_Icon.svg";
import EotBlack from "../../assets/images/Token3D.png";
import Telegramm from "../../assets/images/telegramm.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Cashout from "./../Modal/Cashout";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const { transfer, broadcast } = require("@0bsnetwork/zbs-transactions");

const MySwal = withReactContent(Swal);

class Token extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tokenSaleClosed: false,
      kycIsComplete: false,
      walletaddress: "",
      tokenUserHold: 0,
      totalSupply: 0,
      totalPurchased: 0,
      totalAvailable: 0,
      tokenData: null,
      tokensToBuy: 0,
      loading: true,
      balance: 0,
      tabId: "basic-tab-1",
      show: false,
      isShowing: false,
      tokenBalance: 0,
      eurEarnings: 0,
      eurRevenue: 0,
      totalEurEarnings: 0,
      totalRevenue: 0,
      loading: true,
      monthlyCostEur: 0,
      balance: 0,
      cashbackPercent: 0,
      cashbackTier: "0",
      earningsLastUpdateDateTime: "",
      totalEarnings: 0,
      revenueThisMonth: 0,
      totalCashout: 0,
      revBreakdown: [],
      email: "",
    };
  }

  openModalHandler = () => {
    this.setState({
      isShowing: true,
    });
  };

  closeModalHandler = () => {
    this.setState({
      isShowing: false,
    });
  };

  handleInputTokenBuyChange = (event) => {
    this.setState({
      tokensToBuy: event.target.value,
    });
  };

  buyTokens = () => {
    this.props.history.push("/buy");
  };

  componentWillMount() {
    let token = localStorage.getItem("token");
    if (!token) {
      this.props.history.push("/login");
    } else {
      // get data for cashout balance
      fetch("https://" + API_URL + "/users/me", {
        method: "GET",
        headers: new Headers({
          "X-Parse-Application-Id": APP_ID,
          "X-Parse-REST-API-Key": MASTER_KEY,
          "Content-Type": "application/json",
          "X-Parse-Session-Token": token,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);
          if (data.error && data.code === 209) {
            this.logOut();
          }
          this.setState({
            walletaddress: data.walletaddress ? data.walletaddress : "",
            totalEurEarnings: data.eur_earnings ? data.eur_earnings : 0,
            eurEarnings: data.current_week_earnings
              ? data.current_week_earnings
              : 0,
            balance: data.balance ? data.balance : 0,
            earningsLastUpdateDateTime: data.EarningsLastUpdateDateTime
              ? data.EarningsLastUpdateDateTime
              : "",
            userpercentagerevenue: data.currentPeriodTokenHoursPct,
            email: data.email,
          });

          fetch(
            NODE_URL +
              "/assets/balance/" +
              this.state.walletaddress +
              "/" +
              ASSET_ID
          )
            .then((response) => {
              return response.json();
            })
            .then((balance) => {
              if (balance.balance) {
                this.setState({
                  tokenBalance: balance.balance / 100,
                });
              } else {
                this.setState({ tokenBalance: 0 });
              }
            });
        })
        .catch((err) => {
          console.log(err);
        });

      fetch("https://" + API_URL + "/classes/Settings", {
        method: "GET",
        headers: new Headers({
          "X-Parse-Application-Id": APP_ID,
          "X-Parse-REST-API-Key": MASTER_KEY,
          "Content-Type": "application/json",
          "X-Parse-Session-Token": token,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data["results"][0]["closed"]) {
            this.setState({
              tokenSaleClosed: true,
            });
          }
          this.setState({
            totalSupply: data.results[0].TotalSupply,
            totalEurRevenue: data.results[0].revenuePoolTotal.toFixed(2),
            totalCostEur: data.results[0].costPoolTotal.toFixed(2),
            revBreakdown: [
              data.results[0].revenuePoolTotal.toFixed(2),
              data.results[0].costPoolTotal.toFixed(2),
            ],
          });
        })
        .catch((err) => console.log(err));

      fetch("https://" + API_URL + "/functions/getmystats", {
        method: "POST",
        headers: new Headers({
          "X-Parse-Application-Id": APP_ID,
          "X-Parse-REST-API-Key": MASTER_KEY,
          "Content-Type": "application/json",
          "X-Parse-Session-Token": token,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);
          this.setState({
            daysSinceStart: data.result.daysSinceStart,
            totalEarnings: data.result.totalEarnings,
            revenueThisMonth: data.result.revenueThisMonth,
            totalCashout: data.result.totalCashout,
            proRataProfit: data.result.proRataProfit,
            proRataCosts: data.result.proRataCosts,
          });
        })
        .catch((err) => console.log(err));
      fetch("https://" + API_URL + "/users/me", {
        method: "GET",
        headers: new Headers({
          "X-Parse-Application-Id": APP_ID,
          "X-Parse-REST-API-Key": MASTER_KEY,
          "Content-Type": "application/json",
          "X-Parse-Session-Token": token,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error && data.code === 209) {
            this.logOut();
          }
          this.setState({ ...data });
          localStorage.setItem("objectId", data.objectId);
          this.setState({
            walletaddress: data.walletaddress,
            kycIsComplete: data.kycstatus,
          });

          this.updateBalance();
          this.getData();
        })
        .catch((err) => {
          console.log(err);
        });

      this.interval = setInterval(() => this.getData(), 10000);
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  updateBalance = () => {
    try {
      fetch(
        NODE_URL +
          "/assets/balance/" +
          this.state.walletaddress +
          "/" +
          ASSET_ID
      )
        .then((response) => {
          return response.json();
        })
        .then((balance) => {
          if (balance.balance) {
            let tokenUser =
              (balance.balance / 100 / this.state.totalSupply) * 100;
            let cashbackTier = this.calcCashbackTier(balance.balance / 100);
            this.setState({
              balance: balance.balance / 100,
              loading: false,
              tokenUserHold: tokenUser,
              cashbackTier: `${cashbackTier.tier}`,
              cashbackPercent: cashbackTier.cashback * 100,
            });
          } else {
            this.setState({ balance: 0, loading: false, tokenUserHold: 0 });
          }
        });
    } catch (err) {
      console.log("Error fetching data-----------", err);
    }
  };

  getData = () => {
    fetch("https://" + API_URL + "/classes/Settings/", {
      method: "GET",
      headers: new Headers({
        "X-Parse-Application-Id": APP_ID,
        "X-Parse-REST-API-Key": MASTER_KEY,
        "Content-Type": "application/json",
        "X-Parse-Session-Token": localStorage.getItem("token"),
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        this.setState({ tokenData: data["results"] });
        // let totalPurchased = data["results"][0]["totalPurchased"];
        let totalSupply = data["results"][0]["TotalSupply"];
        // get the left tokens from the wallet directly and the subtract them from the total supply for the REALTIME total purchased amount
        fetch(
          "https://nodes.0bsnetwork.com/assets/balance/3QcpnRhPcjmBK4fh7PMXXmjQ1zf2mU1tWcL/" +
            ASSET_ID,
          {
            method: "GET",
          }
        )
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            this.setState({
              totalPurchased: totalSupply - data.balance / 100,
            });
             this.setState({
              totalAvailable: data.balance / 100,
            });
          })
          .catch((e) => console.log(e));
        this.setState({
          // totalPurchased: totalPurchased,
          totalSupply: totalSupply,
        });
        this.updateBalance();
      })
      .catch((e) => console.log(e));
  };

  logOut = () => {
    localStorage.removeItem("token");
    this.props.history.push("/login");
  };

  processCaseOut = (cashoutAmount) => {
    let token = localStorage.getItem("token");
    if (!token) {
      this.props.history.push("/login");
    }
    if (cashoutAmount < 10) {
      MySwal.fire({
        icon: "warning",
        text: "Minimum payout is 10 €" /*i18n.text("Minimum payout is 10 Euro !"),*/,
      });
      return false;
    }
    if (cashoutAmount > this.state.totalEarnings - this.state.totalCashout) {
      MySwal.fire({
        icon: "warning",
        text: "Your Balance is too low." /*i18n.t("Not enough balance to cashout !"),*/,
      });
      return false;
    }
    if (
      cashoutAmount >= 10 &&
      this.state.totalEarnings - this.state.totalCashout >= cashoutAmount
    ) {
      fetch("https://" + API_URL + "/functions/requestPayout", {
        method: "POST",
        headers: new Headers({
          "X-Parse-Application-Id": APP_ID,
          "X-Parse-REST-API-Key": MASTER_KEY,
          "Content-Type": "application/json",
          "X-Parse-Session-Token": token,
        }),
        body: JSON.stringify({
          amount: cashoutAmount,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error && data.code === 209) {
            this.logOut();
          }
          console.log(data);
          MySwal.fire({
            icon: "success",
            text: "Your cash out request has been sent and will be proccessed at the 15th of the next month." /*i18n.t(
              "Your request has been sent and will be proccessed in the next 5 business days."),*/,
          }).then(() => {
            window.location.reload();
          });
        })
        .catch((err) => {
          console.log(err);
        });
      return true;
    } else {
      MySwal.fire({
        icon: "warning",
        text: "Something went wrong! Please try again later.", //i18n.t("Something went wrong! Please try again later."),
      });
    }
  };

  processCreditConvert = (convertAmount) => {
    let token = localStorage.getItem("token");
    if (!token) {
      this.props.history.push("/login");
    }
    if (convertAmount < 10) {
      MySwal.fire({
        icon: "warning",
        text: "Minimum payout is 10 €" /*i18n.t("Minimum 10 credit require to convert !"),*/,
      });
      return false;
    }
    if (convertAmount > this.state.totalEarnings - this.state.totalCashout) {
      MySwal.fire({
        icon: "warning",
        text: "Your Balance is too low." /*i18n.t("Not enough balance for convert to credit !"),*/,
      });
      return false;
    }
    if (
      convertAmount >= 10 &&
      this.state.totalEarnings - this.state.totalCashout >= convertAmount
    ) {
      // this.state.balance is the token balance
      // console.log("ConvertAmount: " + convertAmount);
      // console.log("state.balance: " + this.state.balance);
      fetch("https://" + API_URL + "/functions/requestCredits", {
        method: "POST",
        headers: new Headers({
          "X-Parse-Application-Id": APP_ID,
          "X-Parse-REST-API-Key": MASTER_KEY,
          "Content-Type": "application/json",
          "X-Parse-Session-Token": token,
        }),
        body: JSON.stringify({
          amount: convertAmount,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error && data.code === 209) {
            this.logOut();
          }
          console.log(data);
          MySwal.fire({
            icon: "success",
            text: "Your cash out request has been sent and will be proccessed at the 15th of the next month." /*i18n.t(
              "Your request has been sent and will be proccessed in the next 5 business days."),*/,
          }).then(() => {
            window.location.reload();
          });
        })
        .catch((err) => {
          console.log(err);
        });
      return true;
    } else {
      MySwal.fire({
        icon: "warning",
        text: "Something went wrong! Please try again later.", //i18n.t("Something went wrong! Please try again later"),
      });
    }
  };
  calcCashbackTier = (eotBalance) => {
    try {
      let promotionMultiplier = 1;
      let tiers = [
        {
          tier: 0,
          minEot: 0,
          maxEot: 0,
          cashback: 0,
        },
        {
          tier: 1,
          minEot: 100,
          maxEot: 249,
          cashback: 0.01 * promotionMultiplier,
        },
        {
          tier: 2,
          minEot: 250,
          maxEot: 549,
          cashback: 0.02 * promotionMultiplier,
        },
        {
          tier: 3,
          minEot: 550,
          maxEot: 999,
          cashback: 0.03 * promotionMultiplier,
        },
        {
          tier: 4,
          minEot: 1000,
          maxEot: 2499,
          cashback: 0.05 * promotionMultiplier,
        },
        {
          tier: 5,
          minEot: 2500,
          maxEot: 4999,
          cashback: 0.06 * promotionMultiplier,
        },
        {
          tier: 6,
          minEot: 5000,
          maxEot: 9999,
          cashback: 0.08 * promotionMultiplier,
        },
        {
          tier: 7,
          minEot: 10000,
          maxEot: 49999,
          cashback: 0.1 * promotionMultiplier,
        },
        {
          tier: 8,
          minEot: 50000,
          maxEot: 1000000,
          cashback: 0.15 * promotionMultiplier,
        },
      ];
      let userTier = tiers.find(
        (item) => item.minEot <= eotBalance && eotBalance <= item.maxEot
      );
      if (userTier) {
        return userTier;
      } else {
        return tiers[0];
      }
    } catch (error) {
      console.log(error);
    }
  };
  //THE 4th EOT SALE IS CURRENTLY PAUSED OR SOLD OUT!
  render() {
    return (
      <div>
      <Container fluid={true} style={{"backgroundColor": "white", marginBottom: "16px"}}>
      <Row className="text-center bg-black">
      <Col className="flex">
      <div className="field">
        <span className="tokendata">
        {this.state.balance} EOT
        </span>
        <p className="label">My Token Balance</p>
      </div>
      <div>
      {this.state.isShowing ? (
        <div onClick={this.closeModalHandler} className="back-drop"></div>
      ) : null}


      {!this.state.kycIsComplete ? (
         
        <div className="data infoBlock field ">You need to do the KYC first to participate in the Token Sale. Go to your Account.</div>
       
      ) : (this.state.tokenSaleClosed) ? (
        <div className="field">
        
        THE NEXT EOT SALE WILL START SOON.
        <p className="label"></p>
        </div>
      ) : this.state.kycIsComplete && this.state.totalAvailable > 0 && (ONLYBUYELOOP && this.state.email.endsWith("@eloop.to")) ? (
        <button className="buybutton" onClick={this.buyTokens}>
        BUY MORE EOT
        </button>


      ) : (this.state.kycIsComplete && this.state.totalAvailable > 0 && !ONLYBUYELOOP) ? (
        <button className="buybutton" onClick={this.buyTokens}>
        BUY MORE EOT
        </button>
      ) : 
        <div className="data field infoblock">
        THE NEXT EOT SALE WILL START SOON.</div>
       
     }

      <Modal
      className="modal"
      header={i18n.t('Buy Token')}
      show={this.state.isShowing}
      close={this.closeModalHandler}
      functionExecute={this.buyTokens}>
      {i18n.t('How many Token do you want to buy?')}
      <br />
      <input value={this.state.tokensToBuy} onChange={this.handleInputTokenBuyChange}/>
      </Modal>
      </div>
      </Col>
      <Col className="flex">
      <div className="field">
        <span className="tokendata">{(this.state.totalEarnings - this.state.totalCashout > 0
          ? this.state.totalEarnings - this.state.totalCashout
          : 0
        ).toFixed(2)} €
        </span>
        <p className="label">CASHOUT BALANCE</p>
      </div>
      <Cashout
        show={true}
        handleCaseOut={this.processCaseOut}
        handleCreditConvert={this.processCreditConvert}
        cashoutBalance={this.state.totalEarnings - this.state.totalCashout}
      />
      </Col>
      </Row>
      </Container>
      <Container fluid={true} style={{"backgroundColor": "#000", marginBottom: "16px"}}>
      <Row  className="text-center">
      <Col className="flex">
      <div className="field">
        <span className="data">
        <img
          style={{ height: "136px" }}
          src={EotBlack}
          alt="Eloop One Logo"
        /><br/>
        ELOOP ONE TOKEN<br/>
        <span className="eotprice">{TOPRICE.toFixed(2)} €</span>
        </span>
        <p className="label"></p>
      </div>
      </Col>

      <Col className="flex">
        <div className="field">
          <span className="tokendata">
          {this.state.totalAvailable} EOT
          </span>
          <p className="label">Available EOT</p>
        </div>
        <div className="field">
          <span className="tokendata">
          {this.state.totalSupply} EOT
          </span>
          <p className="label">Circulating SUPPLY</p>
        </div>
        <div id="myProgress" style={{width: "100%", backgroundColor: "grey", borderRadius:"5px"}}>
        <div id="bar" style={{fontSize:"24px", width: Math.trunc(this.state.totalPurchased / this.state.totalSupply * 100) + "%", height: "36px", backgroundColor: "#4adeb5", color: "white", borderRadius:"5px", marginBottom:"12px"}}>{Math.trunc(this.state.totalPurchased / this.state.totalSupply * 100)}%  EOT SOLD</div>
        </div>
        </Col>
      </Row>
      </Container>
      <Container fluid={true} style={{"backgroundColor": "white", marginBottom: "16px"}}>
      <Row className="text-center bg-black">
      <Col className="flex">
      <div className="field">
        <span className="tokendata">
        {this.state.cashbackPercent}%
        </span>
        <p className="label">My Cashback per Trip</p>
      </div>
      </Col>
      <Col className="flex">
      <div className="field">
        <span className="tokendata">
        {this.state.cashbackTier}
        </span>
        <p className="label">My Cashback Tier</p>
      </div>
      </Col>
      </Row>
      </Container>
      <Container fluid={true} style={{"backgroundColor": "#000", marginBottom: "16px"}}>
       <h1 className="cashbackheader field">Cashback Tiers</h1>
      <Row className="text-center">
      <table className="cashbacktable ">
        <tr>
          <td>1</td>
          <td>2</td>
          <td>3</td>
          <td>4</td>
          <td>5</td>
          <td>6</td>
          <td>7</td>
          <td>8</td>
        </tr>
        <tr >
          <td>100 EOT</td>
          <td>250 EOT</td>
          <td>550 EOT</td>
          <td>1000 EOT</td>
          <td>2500 EOT</td>
          <td>5000 EOT</td>
          <td>10000 EOT</td>
          <td>50000 EOT</td>
        </tr>
        <tr>
          <td>1%</td>
          <td>2%</td>
          <td>3%</td>
          <td>5%</td>
          <td>6%</td>
          <td>8%</td>
          <td>10%</td>
          <td>15%</td>
        </tr>
      </table>
      </Row>
      </Container>
      {/*<Accordion>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="0" className="tripHeader">
        Token Locked

        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
          <Row>
            <Col className="text-center">
            <div className="field">
              <span className="data">5000 EOT</span>
              <p className="label">Amount</p>
            </div>


            </Col>
            <Col className="text-center">
            <div className="field">
              <span className="data"><a href="https://explorer.0bsnetwork.com/address/3QV5Z79xHt7ffRvC2vV4h8wro5ZvU2fCkEU">Locked Wallet</a></span>
              <p className="label">Wallet Address</p>
            </div>
            </Col>
          </Row>

          </Card.Body>
        </Accordion.Collapse>
        <div>

        </div>
      </Card>
    </Accordion>*/}
      </div>
    );
  }
}

export default withRouter(Token);
