import React from "react";
import i18n from "../../i18n";
import { APP_ID, API_URL, MASTER_KEY } from "../../config";
import { Line, defaults, Chart, Radar, Bar } from "react-chartjs-2";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

class GlobalStats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      globalstats: [],
      globalstatslabels: [],
      labels: [],
      weekdaysStats: [],
      longestStreak: 0,
      currentStreak: 0,
      profitDays: 0,
      lossDays: 0
    };
  }

  componentDidMount() {
    this.GetSeries();
  }

  GetSeries = async () => {
    const self = this;
    let token = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("x-parse-application-id", APP_ID);
    myHeaders.append("x-parse-rest-api-key", MASTER_KEY);
    myHeaders.append("x-parse-session-token", token);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      options: this.options,
    };

    const responseGlobal = await fetch(
      "https://"+API_URL+"/functions/getdailystatsglobal",
      requestOptions
    );
    const responseGlobalJson = await responseGlobal.json();
    // console.log(responseGlobalJson.result);

    const globalstatsClone = [...self.state.globalstats];
    let revenueSinceGenesis = [];
    let costSinceGenesis = [];
    let weekStats = [];
    let weekdaysStats = [0, 0, 0, 0, 0, 0, 0];
    let revYesterday = 0;
    let costYesterday = 0;
    let longestStreak = 0;
    let currentStreak = 0;
    let profitDays = 0;
    let lossDays = 0;
    //console.log(responseGlobalJson.result);
    for (let i = 0; i < responseGlobalJson.result.length; i ++) {

    //for (const [ key, value ] of Object.entries(responseGlobalJson.result)) {
        var value = responseGlobalJson.result[i];
        let day = new Date(value.supplyDate);
        let weekday = day.getDay();
        weekdaysStats[weekday] += value.profitPool;
        if (value.profitPool >= 0) {
          /* Streak continues */
          currentStreak++;
          profitDays++;
          if(currentStreak > longestStreak)
            longestStreak = currentStreak;
        } else {
          /* Streak resets */
          currentStreak = 0;
          lossDays++;
        }
        var revenue = value.profitPool + value.costPool;
        var cost = value.costPool;
        this.state.labels.push(i);
        // console.log(value.profitPool);

        revenueSinceGenesis.push(revenue + revYesterday);
        revYesterday += revenue;
        costSinceGenesis.push(cost + costYesterday);
        costYesterday += cost;

        //console.log(key)
        // console.log(value);
        // console.log("Month: " + key + "Profit" + totalMonthValue);
        // console.log("Key: " + key + " Value " + value);
        // globalstatsClone.push({data:})
    }
    /* Resort Array so Sunday is last the day of the week */
    let sunday = weekdaysStats[0];
    weekdaysStats[0] = weekdaysStats[1];
    weekdaysStats[1] = weekdaysStats[2];
    weekdaysStats[2] = weekdaysStats[3];
    weekdaysStats[3] = weekdaysStats[4];
    weekdaysStats[4] = weekdaysStats[5];
    weekdaysStats[5] = weekdaysStats[6];
    weekdaysStats[6] = sunday;
    weekdaysStats[1] -= 2105.42; // EOT Geschenk verfälscht sonst die Grafik, gesamtbetrag stimmt dann halt nicht mehr
    weekStats.push({label: "Operating Profit", data: weekdaysStats, backgroundColor: "rgb(1, 197, 196, 0.8)" });

    globalstatsClone.push({label: "Revenue", data: revenueSinceGenesis, fill: 1, backgroundColor: "rgb(1, 197, 196, 0.8)" });
    globalstatsClone.push({label: "Cost", data: costSinceGenesis, fill: "origin", backgroundColor: "rgb(248, 95, 115, 0.8)" });
    self.setState({
      isLoading: false,
      globalstats: globalstatsClone,
      weekdaysStats: weekStats,
      longestStreak: longestStreak,
      currentStreak: currentStreak,
      profitDays: profitDays,
      lossDays: lossDays
    });
  };
  render() {
      return (
          <div style={{margin: "2rem 0"}}>
          <div>
          <Row style={{marginBottom: "2rem", textAlign: "center", color: "white"}}>
          <Col className="flex">
          <div style={{"backgroundColor": "rgb(0,145,124, 0.5)"}} className="field">
            <span  className="data revdata">{this.state.longestStreak}</span>
            <p style={{color: "white"}} className="label">Longest Streak</p>
          </div>
          </Col>
          <Col className="flex">
          <div style={{"backgroundColor": "rgb(74, 222, 181, 0.5)"}} className="field">
            <span className="data revdata">{this.state.currentStreak}</span>
            <p style={{color: "white"}} className="label">Current Streak</p>
          </div>
          </Col>
          <Col className="flex">
          <div style={{"backgroundColor": "rgb(2,120,174, 0.7)"}} className="field">
            <span className="data revdata">{this.state.profitDays}</span>
            <p style={{color: "white"}} className="label">Days with Profit</p>
          </div>
          </Col>
          <Col className="flex">
          <div style={{"backgroundColor": "rgb(236,70,70, 0.5)"}} className="field">
            <span className="data revdata">{this.state.lossDays}</span>
            <p style={{color: "white"}} className="label">Days without Profit</p>
          </div>
          </Col>
          </Row>

          <Container fluid={true}>
          <Row>
          <Col  className="flex field p-2 ">
          < Line
          
            data={{
              labels: this.state.labels,
              datasets: this.state.globalstats

            }}
            height = {
              600
            }
            width = {
              900
            }
            options = {
              {
                maintainAspectRatio: false,
                title: {
                      display: true,
                      text: 'Revenue Since Genesis',
                      fontSize: 16
                  },
                  legend: {
                      display: true,
                      labels: {
                          boxWidth: 20,
                          // fontSize: 16,
                          // fontStyle: 'bold',
                          //fontColor: '000000'
                      }
                  },
                scales: {
                      yAxes: [{
                          ticks: {
                              // Include a Euro sign in the ticks
                              callback: function(value, index, values) {
                                  return value + ' €';
                              }
                          }
                      }]
                  },
                  tooltips: {
                      callbacks: {
                          label: function(tooltipItem, data) {

                              var label = Math.round(tooltipItem.yLabel * 100) / 100;
                              label += ' €'
                              return label;
                          }
                      }
                  }
              }
            }
          />
          </Col>
          <Col className="flex field p-2">
          {/*<br/>
          <h1 className="revsection">OPERATING PROFIT PER WEEKDAY</h1>
          <br/>*/}
          < Bar
              data={{
                labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
                datasets: this.state.weekdaysStats

              }}
              
              height = {
                600
              }
              width = {
                900
              }
            options = {
              {
                maintainAspectRatio: false,
                title: {
                      display: true,
                      text: 'Operating Profit per Weekday',
                      fontSize: 16
                  },
                  legend: {
                      display: false,
                      labels: {
                          boxWidth: 20,
                          // fontSize: 16,
                          // fontStyle: 'bold',
                          //fontColor: '000000'
                      }
                  },
                scales: {
                      yAxes: [{
                          ticks: {
                            beginAtZero: true,
                              // Include a Euro sign in the ticks
                              callback: function(value, index, values) {
                                  return value + ' €';
                              }
                          }
                      }]
                  },
                  tooltips: {
                      callbacks: {
                          label: function(tooltipItem, data) {

                              var label = Math.round(tooltipItem.yLabel * 100) / 100;
                              label += ' €'
                              return label;
                          }
                      }
                  }
              }
            }
          />
          </Col>
          </Row>
          </Container>
          </div>

          </div>
      );
    }
  }

export default GlobalStats;
