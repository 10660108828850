import React from "react";
import styled from "@emotion/styled";
import Bmw from "../../assets/images/eloop-bmw.png";
import Tesla from "../../assets/images/model3_april.png";
import TeslaY from "../../assets/images/modely_april.png";
import Moment from "moment";
// import momentDurationFormatSetup from 'moment-duration-format';
import i18n from "../../i18n";
const prettyMilliseconds = require('pretty-ms');



const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 1.25em 0.25em;
  box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
    0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
    0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
    0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);

  border: 1px solid rgba(32, 39, 140, 0.125);
  border-radius: 0.25rem;
`;
const Active = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 25px;
`;
const Plate = styled.div`
  background-color: white;
  font-weight: bold;
  font-size: 1.2em;
`;
const Span = styled.span`
  font-weight: 700;
  text-transform: uppercase;
  padding: 5px 10px;
  min-width: 19px;
  color: #fff;
  background-color: rgb(74, 222, 181);
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: initial;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;
const SecondRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  div p {
    font-size: 0.7em;
    text-transform: uppercase;
    text-align: center;
    opacity: 0.5;
  }
  div span {
    font-weight: bold;
  }
`;
const ThirdRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  div p {
    font-size: 0.7em;
    text-transform: uppercase;
    text-align: center;
    opacity: 0.5;
  }
  div span {
    font-weight: bold;
  }
`;

const durationFormatter = (cell) => {
  if (cell) {
    return Moment.duration(Number(cell.toFixed(0)), "minutes").format(
      "H"
    );
  } else return 0;
};


const revPerCarConst = (data) => {
  let costPerCar = (Math.round(Math.abs((new Date - Date.parse(data.data.dayUsingStarts.iso)) / (24 * 60 * 60 * 1000)))-1)*((data.data.monthlyCostEur*12)/360)
  var revPerCar = (data.data.totalRevenue-(costPerCar)).toFixed(2);
  var colorForRevPerCar = "green";
  if(revPerCar < 0) { colorForRevPerCar = "red" }
  return {revPerCar: revPerCar,colorForRevPerCar: colorForRevPerCar, costPerCar: costPerCar}
}


const carImage = (data) => {
  if(data.data.brand.includes('Model 3')) { return Tesla; } else if (data.data.brand.includes('Model Y')) { return TeslaY } else { return Bmw; }
}

const Vehicle = (data) => (

  <Container>
  <Plate>{data.data.plate}</Plate>
  <Span style={{"backgroundColor": "green", color: "white", "textAlign": "left"}}>{data.data.totalCostEt == 60000 ? "August 2020" : "January 2021"} </Span>
    <Active>
    <img
      src={carImage(data)}
      alt="Eloop Bmwi3"
      style={{ paddingRight: "20px", width: "196px", height: "82px", position: "relative", bottom: "10px", left: "24px"}}
    />
      <div className="textvehicle">
        {data.data.info != undefined && data.data.info.isBooked != undefined && data.data.info.isBooked && data.data.info.isBookedByUser ? (
          <>
            <div className="dot-orange">
              <div className="dot-orange-pulse" />
            </div>
            <Span style={{"backgroundColor": "orange"}}>{i18n.t("Booked by User")}</Span>
            <Span style={{display: "block", "backgroundColor": "#fff", color: "black", "textAlign": "left", "paddingLeft": "0px" }}>{i18n.t("Driving since")} {
              data.data.info.bookedSince != undefined && data.data.info.bookedSince > 0
                ? (prettyMilliseconds(new Date() - Date.parse(data.data.info.start_date)))
                : (<>N/A</>)

            }</Span>
          </>
        )

         : (data.data.info != undefined && data.data.info.isBooked != undefined && data.data.info.booking_status == 2) ? (
          <>
            <div className="dot-orange">
              <div className="dot-orange-pulse" />
            </div>
            <Span style={{"backgroundColor": "orange"}}>{i18n.t("Reserved")}</Span>
            <Span style={{display: "block", "backgroundColor": "#fff", color: "black", "textAlign": "left", "paddingLeft": "0px" }}>-</Span>
          </>
        )


        : (data.data.info != undefined && data.data.info.isBooked != undefined && ((data.data.info.isBooked && !data.data.info.isBookedByUser) || data.data.info.booking_status == 4)) ? (
          <>
            <div className="dot-orange">
              <div className="dot-orange-pulse" />
            </div>
            <Span style={{"backgroundColor": "orange"}}>{i18n.t("In Service")}</Span>
            <Span style={{display: "block", "backgroundColor": "#fff", color: "black", "textAlign": "left", "paddingLeft": "0px" }}></Span>
          </>
        )

         : (
          <>
            <div className="dot">
              <div className="dot-pulse" />
            </div>
            <Span>{i18n.t("Available for Booking")}</Span>
           <Span style={{display: "block", "backgroundColor": "#fff", color: "black", "textAlign": "left", "paddingLeft": "0px" }}></Span>
          </>
        )}
        <Span style={{display: "block", "backgroundColor": "#fff", color: "black", "textAlign": "left", "paddingLeft": "0px" }}>{data.data.info != undefined ? ( <>{ data.data.info.autonomy} km left</> ) : (<></>)}</Span>
        <Span style={{display: "block", "backgroundColor": "#fff", color: "black", "textAlign": "left", "paddingLeft": "0px" }}>{data.data.info != undefined && data.data.info.isCharging ? <>is Charging</> : <>not Charging</>} </Span>

      </div>

    </Active>
     <ThirdRow>
      <div>
        <span>€ {data.data.totalRevenue.toFixed(2)}</span>
        <p>{i18n.t("Total Revenue*")}</p>
      </div>
      <div>
        <span>€ {revPerCarConst(data).costPerCar.toFixed(2)} </span>
        <p>{i18n.t("Total Cost*")}</p>
      </div>
      <div>
        <span style={{color: revPerCarConst(data).colorForRevPerCar}}>€ { revPerCarConst(data).revPerCar }</span>
        <p>{i18n.t("Total Profit*")}</p>
      </div>
    </ThirdRow>
    <SecondRow>
      <div>
        <span>{Math.round(data.data.totalKm)} km</span>
        <p>{i18n.t("KM Driven")}</p>
      </div>

      <div>
        <span>{durationFormatter(data.data.totalTime)} h</span>
        <p>{i18n.t("Hours Used")}</p>
      </div>
      <div>
        <span>{Math.ceil(data.data.totalKm * 3.3 * 0.165)} kg</span>
        <p>
          CO<sub>2</sub> Saved
        </p>
      </div>

      <div>
        <span>{data.data.totalCostEt} EOT</span>
        <p>{i18n.t("Value")}</p>
      </div>
      {/*   <div>*/}
      {/*   <span>€ {data.data.monthlyCostEur}</span>*/}
      {/*   <p>{i18n.t("Cost/Month")}</p>*/}
    {/*   </div>*/}
    </SecondRow>
    {/* <p>Total Cost: € {data.data.totalCostEur}</p> */}
    {/* <span>{console.log(data.data)}</span> */}
  </Container>
);

export default Vehicle;
