import React from 'react';
import styled from '@emotion/styled';
import Moment from 'moment';
import Bmw from "../../assets/images/bmw-small.png";
import Tesla from "../../assets/images/tesla-small.png";
import { NODE_EXPLORER_URL } from '../../config';
import i18n from '../../i18n';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

// const Container = styled.div`
//   display: grid;
//   grid-template-columns: 1fr 1fr 1fr 1fr;
//   background-color: white;
//   padding: 2em;
//   margin: 2em 0;
//   box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
//     0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
//     0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
//     0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
//   border: 1px solid rgba(32, 39, 140, 0.125);
//   border-radius: 0.25rem;
//   @media (max-width: 660px) {
//     padding: 1em;
//     font-size: 0.75em;
//   }
// `;
const Values = styled.div`
  span {
    font-weight: bold;
    font-size: 1.2em;
  }
  p {
    font-size: 0.7em;
    opacity: 0.5;
    text-transform: uppercase;
  }
`;
const Link = styled.span`
  text-align: right;
  span {
    font-weight: bold;
  }
  p {
    font-size: 0.7em;
    opacity: 0.5;
    text-transform: uppercase;
    text-align: right;
  }
`;
const ChainLink = styled.a`
  color: #49deb5;
  opacity: 1;
  &:hover {
    color: #3db997;
  }
`;

const durationFormatter = (cell) => {
  let duration = '';
  cell = Math.round(cell);
  if(isNaN(cell)) {
    return '0';
  } else {
    if(cell > 1440) {
      duration = Math.trunc(cell / 1440) + " d ";
      cell = cell % 1440;
    }
    if (cell > 60) {
      duration = duration + Math.trunc(cell / 60) + " h ";
      cell = cell % 60;
    }
    duration = duration + cell + " min";
    return duration;
  }
  // Old format
  // return isNaN(cell)
  //   ? '0'
  //   : Moment.duration(Number(cell.toFixed(0)), 'minutes').format('H:mm');
};
const dateFormatter = (inputDate) => {
  var date = new Date(inputDate);
  if (!isNaN(date.getTime())) {
    var day = date.getDate().toString();
    var month = (date.getMonth() + 1).toString();
    var minutes = date.getMinutes();
    // Months use 0 index.

    return (
      (day[1] ? day : "0" + day[0]) +
      "." +
      (month[1] ? month : "0" + month[0]) +
      "." +
      date.getFullYear() +
      " " + date.getHours() + ":" + (minutes < 10 ? "0" + minutes : minutes)
    );
  }
  return "";
};
const txidFormatter = (cell) => {
  return cell !== undefined ? (
    <ChainLink href={`${NODE_EXPLORER_URL}${cell}`} target='_blank'>
      {i18n.t('Transaction')}
    </ChainLink>
  ) : (
    <span style={{ color: '#f7b924' }}>{i18n.t('Processing')}</span>
  );
};

// const revenueColor = (amount) => {
//   if (amount > 100)
//     return "#FFD700";
//   else
//     return "black";
// };

const tripEmoji = (amount) => {
  if (amount > 500)
    return "🔥🔥🔥🔥🔥";
  else if (amount >= 100)
    return "🔥🔥🔥";
  else
    return "🔥";
};

const tripIcon = (plate) => {
  if (plate && (plate == "W90836G" || plate == "W90838G" || plate == "W63057H" || plate == "W63059H"))
    return Bmw;
  else
    return Tesla;
};

const Trip = (data) => (
  <Accordion >
  <Card className='bg-darkGray'> 
    <Accordion.Toggle as={Card.Header} eventKey="0" className="tripHeader  trips-card">
    <Row className='trip-row'>
      <Row className='items-center'>
      <Col className="flex">
      {tripEmoji(data.data.billingAmount)}
    </Col>    <Col className="text-sm ">
      {data.data.vehicle ? data.data.vehicle : 'N/A'}
    </Col>
    </Row>
    <Col className="flex text-md">
      {data.data.billingAmount.toFixed(2)} €
    </Col>
    <Col className="flex text-md">
      {durationFormatter(data.data.duration)}
    </Col>
   
    <Col className="flex">
      {txidFormatter(data.data.txid)}
    </Col>
  
    </Row>
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="0" className='bg-black rounded-lg' >
      <Card.Body>
      <Row>
        <Col className="text-center">
        <div className="field ">
          <span className="data">{data.data.vehicle ? data.data.vehicle : 'N/A'}</span>
          <p className="label">LICENSE PLATE</p>
        </div>
        <div className="field ">
          <span className="data">{data.data.billingAmount.toFixed(2)} €</span>
          <p className="label">REVENUE</p>
        </div>
        <div className="field">
          <span className="data">{data.data.distance.toFixed(2)} km</span>
          <p className="label">KM Driven</p>
        </div>
        <div className="field">
          <span className="data">{txidFormatter(data.data.txid)}</span>
          <p className="label">BLOCKCHAIN PROOF</p>
        </div>
        </Col>
        <Col className="text-center">
        <div className="field">
          <span className="data">{dateFormatter(data.data.startDateTime.iso).slice(0,10)}</span>
          <p className="label">START DATE</p>
        </div>
        <div className="field">
          <span className="data">{dateFormatter(data.data.startDateTime.iso).slice(10,16)}</span>
          <p className="label">START TIME</p>
        </div>
        <div className="field">
          <span className="data">{dateFormatter(data.data.endDateTime.iso).slice(10,16)}</span>
          <p className="label">END TIME</p>
        </div>
        <div className="field">
          <span className="data">{durationFormatter(data.data.duration)}</span>
          <p className="label">DURATION</p>
        </div>
        </Col>
      </Row>

      </Card.Body>
    </Accordion.Collapse>
    <div>

    </div>
  </Card>
</Accordion>
);

export default Trip;
